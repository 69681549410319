import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IPointOfSale, IProductData } from "./Product.types";
import { useQuery } from "@tanstack/react-query";
import { getAllPointOfSales, getProducts } from "UpdatedFeatures/api";
import { FaRegCircleCheck } from "react-icons/fa6";
import { TiDeleteOutline } from "react-icons/ti";
import useSubCategoryList from "features/SubCategory/SubCategoryList/useSubCategoryList";
import useSuppliersList from "features/Supplier/SuppliersList/useSuppliersList";
import useBrandList from "features/Brand/BrandList/useBrandList";
import { ISuppliersData } from "features/Supplier/SuppliersList/Suppliers.types";
import * as XLSX from "xlsx";
import { CiClock1 } from "react-icons/ci";
import { GrPowerReset } from "react-icons/gr";
import {
  Grid,
  IconButton,
  Tooltip,
  Checkbox,
  Button,
  Box,
  Modal,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import AddButton from "components/common/AddButton/AddButton";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import DraftButton from "components/common/DraftButton/DraftButton";
import PublishButton from "components/common/PublishButton/PublishButton";
import SelectInput from "components/common/SelectInput/SelectInput";
import { ICategoryData } from "features/Category/CategoryList/Category.types";
import ButtonGroup from "@mui/material/ButtonGroup";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
  InputStyle,
} from "./Product.style";
import { Delete, Edit, FileCopy } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import useProductList from "./useProductList";
import DeleteProduct from "../ProductDelete/DeleteProduct";
import DupliqueProduct from "../ProductDuplique/DupliqueProduct";
import { useNavigate } from "react-router-dom";
import {
  useDeleteMultipleProductMutation,
  useGetPublishDraftProductMutation,
} from "redux/api/product/product";
import BarcodeScanner from "components/common/BarcodeScanner/BarcodeScanner";
import Barcode from "react-barcode";
import { useSnackBar } from "hooks/useSnackBar";
import DeleteProductMultiple from "../ProductDeleteMultiple/DeleteProductMultiple";
import Dialog from "components/common/Dialog/Dialog";
import TextInput from "components/common/TextInput/TextInput";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "../ProductAdd/Product.style";
import FileInput from "components/common/FileInput/FileInput";
import AddCSV from "../AddModalProductCSV/AddModalProductCSV";
import ImportButton from "components/common/ImportButton/ImportButton";
import ExportButton from "components/common/ExportButton/ExportButton";
import PublishProduct from "../ProductPublish/PublishProduct";
import DraftProduct from "../ProductDraft/DraftProduct";
import ProductPublish from "../ProductDraftPublishAlert/ProductDraftPublishAlert";
import ProductPublishAlert from "../ProductDraftPublishAlert/ProductDraftPublishAlert";
import {
  Popover,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table as TT,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FaFileExport } from "react-icons/fa";
import ApplyTaxButton from "components/common/ApplyTax/ApplyTaxButton";
import ApplyCEButton from "components/common/ApplyCateg.jsx/ApplyCategButton";
import TaxModal from "./TaxModal";
import CEModal from "./CEModal";
import Alert from "../AddModalProductCSV/Alert";
import ApplyStock from "components/common/ApplyStockPlus/ApplyStock";
import ApplyStockModal from "./StockPlusModal";
import WarningModal from "./FilterAlert";
import { useGetAllCategoryQuery } from "redux/api/category/category";

function Product() {
  const [isScannerModalOpen, setScannerModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [confirmedBarcode, setConfirmedBarcode] = useState<string | null>(null);
  const handleOpenScannerModal = () => {
    setScannerModalOpen(true);
  };
  const { t } = useTranslation();
  interface PointOfSale {
    name: string;
    stock: number;
    someOtherValue: number;
  }

  const renderRows = ({
    points_of_sale,
  }: {
    points_of_sale: PointOfSale[];
  }) => {
    if (!points_of_sale || points_of_sale.length === 0) return null;

    let rows: JSX.Element[] = []; // Explicitly type the 'rows' array

    const renderRecursive = (pointList: PointOfSale[]) => {
      pointList.forEach((point, index) => {
        rows.push(
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {point.name}
            </TableCell>
            <TableCell align="right">{point.stock}</TableCell>
            <TableCell align="right">{point.someOtherValue}</TableCell>
          </TableRow>
        );
      });
    };

    renderRecursive(points_of_sale);
    return rows;
  };

  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [openPublishModalAlert, setOpenPublishModalAlert] = useState(false);
  const [openApplyTaxModal, setOpenApplyTaxModal] = useState(false);
  const [openApplyCEModal, setOpenApplyCEModal] = useState(false);
  const [openApplyStockModal, setOpenApplyStockModal] = useState(false);

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const handleCloseScannerModal = () => {
    setScannerModalOpen(false);
  };
  const openPublishDialogStatusAlert = () => {
    setOpenPublishModalAlert(true);
  };
  const openDeleteMultipleDialogStatus = () => {
    setOpenDeleteMultipleModal(true);
  };
  const openDraftDialogStatus = () => {
    setOpenDraftModal(true);
  };
  const openPublishDialogStatus = () => {
    setOpenPublishModal(true);
  };

  const [deleteCode, { data, error, isLoading: loading, reset }] =
    useDeleteMultipleProductMutation();
  const [
    publishDraft,
    {
      data: publishDraftData,
      error: ErrorPublishDraft,
      isLoading: loadingDraft,
      reset: resetPubDra,
    },
  ] = useGetPublishDraftProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_deleted"));
    reset();
  }
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  if (ErrorPublishDraft) {
    displayErrorApiSnackBar(ErrorPublishDraft);
    resetPubDra();
  }
  if (publishDraftData?.data == "success" && publishDraftData?.data) {
    console.log("publish", publishDraftData?.data);
    displaySuccessSnackBar(t("product.status_updated"));
    resetPubDra();
  }

  const handleConfirmBarcode = (barcode: string | null) => {
    // Handle the confirmed barcode value
    setConfirmedBarcode(barcode);
    // Close the scanner modal
    setScannerModalOpen(false);
    onSearchChange(barcode ?? "");
  };

  const navigate = useNavigate();

  const {
    onChangeSelectCategory,
    handleSelectChange,
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    productData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    categoryData,
    category,
    subcategories,
    subCategory_id,
    selectedPOS,
    onChangeSelectSubCategory,
    changeFilter,
    filter,
    changeGarantieFilter,
    garantie,
    date_limite_de_consommation,
    changeDateLimiteConsFilter,
    changeStatus,
    status,
    resetAllFilters,
    selectedStatus,
    handleStatusSelectChange,
    selectAll,
    setSelectAll,
    isAlertOpen,
    setAlertOpen,
  } = useProductList(false);

  // Define the interface for PointOfSale
  interface PointOfSale {
    name: string;
    address: string;
  }
  console.log(productData);
  // Define the interface for Product

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openDupliqueDialog, setOpenDupliqueDialog] = useState<boolean>(false);
  const [showModalImportCSV, setShowModalImportCSV] = useState(false);
  const [dupliquedProduct, setDupliquedProduct] = useState<{
    id: number;
    title: string;
  }>();
  const [deletedProduct, setDeletedProduct] = useState<{
    id: number;
    title: string;
  }>();
  const openDeleteDialogStatus = useCallback(
    (id: number, title: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedProduct({ id: id, title: title });
    },
    [setOpenDeleteDialog, setDeletedProduct]
  );
  const openDupliqueDialogStatus = useCallback(
    (id: number, title: string) => () => {
      setOpenDupliqueDialog(true);
      setDupliquedProduct({ id: id, title: title });
    },
    [setOpenDupliqueDialog, setDupliquedProduct]
  );
  const navigateToAddPage = () => {
    navigate("/" + RouteIdEnum.Products + "/" + RouteIdEnum.Add);
  };
  const handleShowModalExportCSV = () => {
    setShowModalImportCSV(!showModalImportCSV);
  };

  const navigateToEditPage = useCallback(
    (id: number) => () => {
      console.log("id", id);

      navigate("/" + RouteIdEnum.Products + "/" + RouteIdEnum.Edit + "/" + id);
    },
    [navigate]
  );

  const [touch, setTouch] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setTouch(true);
    setSelectAll(!selectAll);
  };
  const { data: categories } = useGetAllCategoryQuery({
    all: true,
  });
  const { subCategoryData } = useSubCategoryList();
  console.log("hh", subCategoryData);
  const subs = subCategoryData?.data;
  console.log("g", subs);
  const { allBrandData } = useBrandList();
  console.log("h", allBrandData);
  const { allSuppliersData } = useSuppliersList();
  const [isExporting, setIsExporting] = useState(false);
  const { data: prods, refetch } = useQuery({
    queryKey: [
      "products",
      search,
      status,
      garantie,
      category,
      subCategory_id,
      date_limite_de_consommation,
      selectedPOS,
      selectedStatus,
      selectedItems,
      selectAll,
    ],
    queryFn: () =>
      getProducts(
        search,
        status,
        garantie,
        category,
        subCategory_id,
        date_limite_de_consommation,
        selectedPOS,
        selectedStatus,
        selectedItems,
        selectAll
      ),
    enabled: false,
  });
  console.log(prods);
  const handleExport = async () => {
    setIsExporting(true);

    // Fetch the data
    const refetchResult = await refetch();
    setTimeout(() => {
      const data = refetchResult?.data;
      console.log(data);

      interface Product {
        // id: number;
        title: string;
        description: string | null;
        HT_price: number | null;
        price: number | null;
        priceLolo: number | null;
        priceNonSubscriber: number | null;
        priceLoloTtc: number | null;
        priceNonSubscriberTtc: number | null;
        quantity: number | null;
        unite_format: string;
        format: string | null;
        barcode: string | null;
        subcategory_product_id: number;
        provider_id: string;
        brand_id: number;
      }
      const subCategoryMapping = Object.fromEntries(
        subs?.map((subcategory: { id: any; title: any }) => [
          subcategory.id,
          subcategory.title,
        ])
      );
      console.log("hh", subCategoryMapping);
      const brandMapping = Object.fromEntries(
        allBrandData?.map((brand: { id: any; name: any }) => [
          brand.id,
          brand.name,
        ])
      );
      const supplierMapping = Object.fromEntries(
        allSuppliersData?.map((supplier: { id: any; company_name: any }) => [
          supplier.id,
          supplier.company_name,
        ])
      );
      const exportData = data?.data.map((product: Product) => ({
        // ID: product?.id,
        TITRE: product?.title,
        DESCRIPTION: product?.description,
        PRIX_ACHAT_HT: product?.HT_price,
        PRIX_HT_KDMARCHE: product?.price,
        PRIX_HT_LOLODRIVE_ABONNE: product?.priceLolo,
        PRIX_HT_LOLODRIVE_NON_ABONNE: product?.priceNonSubscriber,
        FORMAT: product?.format,
        TYPE_DE_FORMAT: product?.unite_format,
        GENCODE: product?.barcode,
        QUANTITE_kdmarche: product?.quantity,
        SOUS_CATEGORIE: subCategoryMapping[product?.subcategory_product_id],
        FOURNISSEUR_RAISON_SOCIALE: supplierMapping[product?.provider_id],
        MARQUE: brandMapping[product?.brand_id],
      }));

      console.log(exportData);

      // Create Excel file
      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

      XLSX.writeFile(workbook, "products_data.xlsx");

      setIsExporting(false);
    }, 1000); // Wait for 1 second (1000 ms)
  };
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const deleteMultiple = (event: any) => {
    event.preventDefault();
    deleteCode({
      product_ids: selectedItems,
      select_all: selectAll,
      search: search,
      status: status,
      garantie: garantie,
      category: category,
      subCategory_id: subCategory_id,
      date_limite_de_consommation: date_limite_de_consommation,
      selectedPOS: selectedPOS,
      selectedStatus: selectedStatus,
    });
    if (!error) {
      setOpenDeleteMultipleModal(false);
    }
  };

  const publishDraftHandler = (e: any, status: number) => {
    e.preventDefault();
    publishDraft({
      product_ids: selectedItems,
      select_all: selectAll,
      search: search,
      status: status,
      garantie: garantie,
      category: category,
      subCategory_id: subCategory_id,
      date_limite_de_consommation: date_limite_de_consommation,
      selectedPOS: selectedPOS,
      selectedStatus: selectedStatus,
    });
    if (!error) {
      setOpenDraftModal(false);
      setOpenPublishModal(false);
    }
  };
  const [stockAlert, setStockAlert] = useState(0);
  const [selectedProductPointsOfSale, setSelectedProductPointsOfSale] =
    useState<any>([]);
  const handleDotClick = (
    color: string,
    stockAlert: number,
    product: IProductData
  ) => {
      setOpenModal(true);
      setSelectedStockAlert(stockAlert);
      setSelectedProductPointsOfSale(product.points_of_sale); 
      setStockAlert(product.stock_alert);
  };
  const getKDStockBadgeColor = (quantity: number, stockAlert: number) => {
    return quantity <= stockAlert ? "error" : "success";
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStockAlert, setSelectedStockAlert] = useState<number | null>(
    null
  );
  const [activeBadge, setActiveBadge] = useState<number | null>(null);

  // Function to handle closing the modal
  const handleModalClose = () => {
    setOpenModal(false);
  };
  // Handle popover opening on hover
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle popover closing
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  interface PosData {
    id: number;
    name: string;
    address: string;
    contact_number: string;
    latitude: string;
    longitude: string;
    created_at: string;
    updated_at: string;
  }

  const { data: pos } = useQuery({
    queryKey: ["pointOfSales"],
    queryFn: () => getAllPointOfSales(),
  });

  // Specify the type as an array of PosData
  const [updatedPosData, setUpdatedPosData] = useState<PosData[]>([]);

  useEffect(() => {
    if (pos?.data) {
      const appendedData: PosData[] = [
        ...pos.data,
        {
          id: 5,
          name: "KD Marché",
          address: "France",
          contact_number: "stddddring",
          latitude: "0.00000000",
          longitude: "0.00000000",
          created_at: "2024-08-16T14:08:32.000000Z",
          updated_at: "2024-08-19T10:57:50.000000Z",
        },
      ];
      setUpdatedPosData(appendedData);
    }
  }, [pos]);

  const posData = updatedPosData.filter((item) => item.name !== "KD Marché");
  const kdMarcheData = updatedPosData.find((item) => item.name === "KD Marché");
  console.log(updatedPosData);

  return (
    <DashboardLayout
      title={t("product.page_title")}
      selectedPath={RouteIdEnum.Products}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>

        <Alert
          open={isModalOpen}
          setOpen={setModalOpen}
          text={
            "Importation réussie, mais certaines lignes ont échoué. le fichier CSV des erreurs est telechargé."
          }
          title={"Attention!"}
        />
        <TaxModal
          open={openApplyTaxModal}
          setOpen={setOpenApplyTaxModal}
          productsIds={selectedItems}
          selectAll={selectAll}
          search={search}
          status={status}
          garantie={garantie}
          category={category}
          subCategory_id={subCategory_id}
          date_limite_de_consommation={date_limite_de_consommation}
          selectedPOS={selectedPOS}
          selectedStatus={selectedStatus}
        />
        <CEModal
          open={openApplyCEModal}
          setOpen={setOpenApplyCEModal}
          productsIds={selectedItems}
          selectAll={selectAll}
          search={search}
          status={status}
          garantie={garantie}
          category={category}
          subCategory_id={subCategory_id}
          date_limite_de_consommation={date_limite_de_consommation}
          selectedPOS={selectedPOS}
          selectedStatus={selectedStatus}
        />
        <ApplyStockModal
          open={openApplyStockModal}
          setOpen={setOpenApplyStockModal}
          productsIds={selectedItems}
          selectAll={selectAll}
          search={search}
          status={status}
          garantie={garantie}
          category={category}
          subCategory_id={subCategory_id}
          date_limite_de_consommation={date_limite_de_consommation}
          selectedPOS={selectedPOS}
          selectedStatus={selectedStatus}
        />
        <ButtonContainerStyle
          item
          style={{
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          {(selectAll || selectedItems.length != 0) && (
            <ApplyStock onClick={() => setOpenApplyStockModal(true)} />
          )}
          {(selectAll || selectedItems.length != 0) && (
            <ApplyTaxButton
              onClick={() => setOpenApplyTaxModal(true)}
              title={"appliquer tax"}
            />
          )}
          {(selectAll || selectedItems.length != 0) && (
            <ApplyCEButton onClick={() => setOpenApplyCEModal(true)} />
          )}
          {(selectAll || selectedItems.length != 0) && (
            <DraftButton
              onAddClick={openDraftDialogStatus}
              title={t("common.draft")}
            />
          )}
          {(selectAll || selectedItems.length != 0) && (
            <PublishButton
              onAddClick={openPublishDialogStatus}
              title={t("common.publish")}
            />
          )}
          {(selectAll || selectedItems.length != 0) && (
            <DeleteButton
              onAddClick={openDeleteMultipleDialogStatus}
              title={t("common.delete")}
            />
          )}
          &nbsp;
          <AddButton onAddClick={navigateToAddPage} title={t("common.add")} />
          <ExportButton onAddClick={handleExport} title={"Export"} />
          <ImportButton
            onAddClick={handleShowModalExportCSV}
            title={t("common.import_csv")}
          />
          <Button onClick={resetAllFilters}>
            <GrPowerReset color="purple" size={26} />
          </Button>
        </ButtonContainerStyle>
      </Grid>
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          {categories && (
            <SelectInput
              none={true}
              label={t("product.category")}
              error={""}
              value={category}
              onChange={onChangeSelectCategory}
              data={[
                { id: "all", name: "All" },
                ...categories.data?.map((e: ICategoryData) => ({
                  id: e.id,
                  name: e.title,
                })),
              ]}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {category && subcategories ? (
            <SelectInput
              none={true}
              label={t("product.subcategory")}
              error={""}
              value={subCategory_id}
              onChange={onChangeSelectSubCategory}
              data={[
                { id: "all", name: "All" },
                ...subcategories.data?.map((e: ICategoryData) => ({
                  id: e.id,
                  name: e.title,
                })),
              ]}
            />
          ) : (
            <SelectInput
              none={true}
              label={t("product.subcategory")}
              error={""}
              value={subCategory_id}
              data={[
                {
                  id: "",
                  name: "Sélectionnez d'abord une Catégorie",
                },
              ]}
              onChange={function (e: string): void {
                throw new Error("Function not implemented.");
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          <SelectInput
            none={true}
            label="Garantie"
            error={""}
            value={garantie}
            onChange={changeGarantieFilter}
            data={[
              { id: "none", title: "None" },
              { id: 3, title: "3" },
              { id: 6, title: "6" },
              { id: 9, title: "9" },
              { id: 12, title: "12" },
              { id: 18, title: "18" },
              { id: 24, title: "24" },
              { id: 30, title: "30" },
              { id: 36, title: "36" },
            ].map((e: any) => ({
              id: e.id,
              name: e.title,
            }))}
          />
        </Grid>
      </Grid>
      <Grid container mb={2} justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                onClick={() =>
                  handleSelectChange(kdMarcheData?.id?.toString() || "")
                } 
                variant={
                  selectedPOS === kdMarcheData?.id?.toString()
                    ? "contained"
                    : "outlined"
                }
                sx={{
                  height: "100%", 
                  minHeight: "56px"
                }}
              >
                KD Marché
              </Button>
            </Grid>

            <Grid item xs={12} sm={4}>
              {posData && (
                <SelectInput
                  none={true}
                  label="POS"
                  error={""}
                  value={selectedPOS}
                  onChange={handleSelectChange}
                  data={posData
                    .filter((option: PosData) => option.name !== "KD Marché")
                    .map((option: PosData) => ({
                      id: option.id,
                      name: option.name,
                    }))}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={4}>
              <SelectInput
                none={true}
                label="Statut"
                error={""}
                value={selectedStatus || ""}
                onChange={handleStatusSelectChange}
                data={[
                  { id: 1, title: "Tous" },
                  { id: 2, title: "En Stock" },
                  { id: 3, title: "En Rupture" },
                ].map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonGroup
            fullWidth
            variant="outlined"
            aria-label="Basic button group"
          >
            <Button
              onClick={() => changeStatus("all")}
              variant={status === null ? "contained" : "outlined"}
              sx={{ flexGrow: 1 }}
            >
              Tous
            </Button>

            <Button
              onClick={() => changeStatus(2)}
              variant={status === 2 ? "contained" : "outlined"}
              sx={{ flexGrow: 1 }}
            >
              Publié
            </Button>
            <Button
              onClick={() => changeStatus(1)}
              variant={status === 1 ? "contained" : "outlined"}
              sx={{ flexGrow: 1 }}
            >
              Brouillon
            </Button>
            <Button
              onClick={() => changeStatus(3)}
              variant={status === 3 ? "contained" : "outlined"}
              sx={{ flexGrow: 1 }}
            >
              Différé
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ButtonGroup
            fullWidth
            variant="outlined"
            aria-label="Basic button group"
          >
            <Button
              onClick={() => changeDateLimiteConsFilter("all")}
              variant={
                date_limite_de_consommation === null ? "contained" : "outlined"
              }
              sx={{ flexGrow: 1 }}
            >
              Tous
            </Button>

            <Button
              onClick={() => changeDateLimiteConsFilter("dlc")}
              variant={date_limite_de_consommation ? "contained" : "outlined"}
              sx={{ flexGrow: 1 }}
            >
              DLC Terminée
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <div className="flex justify-between items-center w-full mb-3 mx-2">
        <span className="font-semibold text-[#b06bae] ">
          Total: {productData?.total}
        </span>
        <Button onClick={handleOpenScannerModal}> Scanner code à barre </Button>
      </div>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: (
              <Checkbox
                color="secondary"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            ),
          },
          {
            key: "statut",
            title: "Status",
            
          },
          {
            key: "title",
            title: t("product.title"),
            onClick: onOrderChange("title"),
            asc: orderBy === "title" ? orderDir === "asc" : undefined,
          },

          {
            key: "price",
            title: t("product.price"),
            onClick: onOrderChange("price"),
            asc: orderBy === "price" ? orderDir === "asc" : undefined,
          },
          {
            key: "quantity",
            title: t("product.quantity"),
            onClick: onOrderChange("quantity"),
            asc: orderBy === "quantity" ? orderDir === "asc" : undefined,
          },
          {
            key: "kdStock",
            title: t("Stock KD"),
          },
          {
            key: "created_at",
            title: t("common.created_at"),
          },
          {
            key: "barcode",
            title: "barcode",
            onClick: onOrderChange("quantity"),
            asc: orderBy === "quantity" ? orderDir === "asc" : undefined,
          },
          { key: "stock", title: " Stock Lolo Drive" },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={productData?.data.map((e: IProductData) => {
          //code ashraf

          //           const getExpirationStatus = (expirationDate: any) => {
          //             console.log(expirationDate)
          //             if (!expirationDate) { return "ok";}
          //             const today = new Date();
          //             const expiration = new Date(expirationDate);
          //             const expiration15 = new Date(expirationDate);
          //             expiration15.setDate(15);

          //             /* // Calculate the time difference in days
          //              const timeDifference = expiration.getTime() - today.getTime();
          //             const daysDifference = Math.ceil(
          //               timeDifference / (1000 * 3600 * 24)
          //             );
          //  */
          //             // Return expiration status: "expired" if past, "near" if <= 30 days, "ok" otherwise
          //             if (expiration < today) {
          //               return "expired";
          //             } else if (expiration15 < today) {
          //               return "near";
          //             } else {
          //               return "ok";
          //             }
          //           };

          //code meryam
          const getExpirationStatus = (expirationDate: any) => {
            console.log(expirationDate);
            if (!expirationDate) {
              return "ok";
            }

            const today = new Date();
            const expiration = new Date(expirationDate);

            // Calculate the time difference in days
            const timeDifference = expiration.getTime() - today.getTime();
            const daysDifference = Math.ceil(
              timeDifference / (1000 * 3600 * 24)
            );

            // Return expiration status: "expired" if past, "near" if <= 30 days, "ok" otherwise
            if (expiration < today) {
              return "expired";
            } else if (daysDifference <= 15) {
              return "near";
            } else {
              return "ok";
            }
          };
          const expirationStatus = getExpirationStatus(
            e.date_limite_de_consommation
          );

          const kdStockBadgeColor = getKDStockBadgeColor(
            e.quantity,
            e.stock_alert
          );
          // Define row background color based on expiration status
          const rowStyle = {
            backgroundColor:
              expirationStatus === "expired"
                ? "#ffa9a9"
                : expirationStatus === "near"
                ? "#fffc8e"
                : "transparent",
          };
          // Determine the badge color based on the `availability` attribute
          const getBadgeColor = (availability: string) => {
            switch (availability) {
              case "all":
                return "success"; // Green
              case "some":
                return "warning"; // Yellow
              case "none":
                return "error"; // Red
              default:
                return null; // No color
            }
          };

          const badgeColor = getBadgeColor(e.availability);

          // Function to handle the dot click event
          /* const handleDotClick = (
            color: string,
            stockAlert: number,
            index: number
          ) => {
            if (color === "warning") {
              setOpenModal(true);
              setSelectedStockAlert(stockAlert);
              console.log(stockAlert, "pp");
            }
          }; */
          const handleMouseEnter = (
            event: React.MouseEvent<HTMLDivElement>,
            index: number
          ) => {
            setAnchorEl(event.currentTarget);
            setActiveBadge(index);
          };

          const handleMouseLeave = () => {
            setAnchorEl(null);
            setActiveBadge(null);
          };
          return {
            bgcolor: rowStyle.backgroundColor,
            checkbox: (
              <Checkbox
                checked={
                  (!selectAll && selectedItems.includes(e.id)) ||
                  (selectAll && !selectedItems.includes(e.id))
                }
                onChange={() => handleCheckboxChange(e.id)}
              />
            ),
            statut: (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {e.status === 1 ? (
                  <TiDeleteOutline color="red" size={26} style={{ flexShrink: 0 }} />
                ) : e.status === 2 ? (
                  <FaRegCircleCheck color="green" size={20} style={{ flexShrink: 0 }} />
                ) : e.status === 3 ? (
                  <CiClock1 color="yellow" size={24} style={{ flexShrink: 0 }} />
                ) : null}
              </div>
            ),
            title: (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                
                <p style={{ margin: 0, flex: 1 }}> {e.title} </p>
              </div>
            ),

            price: <p>{e.price}</p>,
            quantity: <p>{e.quantity}</p>,
            kdStock: (
              <>
                <StatusBadge isEnabled={true} color={kdStockBadgeColor} />
              </>
            ),
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            barcode: e.barcode ? (
              <div style={{ width: "100%", display: "flex" }}>
                <Barcode value={`${e.barcode}`} height={30} fontSize={15} />
              </div>
            ) : null,
            stock: (
              <>
                {badgeColor && (
                  <div
                    onMouseEnter={(event) => handleMouseEnter(event, e.id)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleDotClick(badgeColor, e.stock_alert, e)}
                  >
                    <StatusBadge color={badgeColor} />
                  </div>
                )}

                {badgeColor && (
                  <Popover
                    sx={{ pointerEvents: "none" }}
                    open={Boolean(anchorEl) && activeBadge === e.id} // Only show for active badge
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={() => setAnchorEl(null)}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 2 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StatusBadge color="success" /> &nbsp; &nbsp; En Stock
                        dans tous les points de vente
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StatusBadge color="warning" /> &nbsp; &nbsp; En Stock
                        dans quelques points de vente
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StatusBadge color="error" /> &nbsp; &nbsp; Hors Stock
                      </div>
                    </Typography>
                  </Popover>
                )}
              </>
            ),

            // Action field
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip title={"Dupliquer"}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDupliqueDialogStatus(e.id, e.title)}
                    >
                      <FileCopy />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={navigateToEditPage(e.id)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.title)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: productData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {showModalImportCSV && (
        <AddCSV
          setAlert={() => setModalOpen(true)}
          openImportCSVDialog={showModalImportCSV}
          setOpenImportingCSVDialog={setShowModalImportCSV}
        />
      )}
      {deletedProduct && (
        <DeleteProduct
          id={deletedProduct.id}
          title={deletedProduct.title}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteProduct>
      )}
      <WarningModal open={isAlertOpen} setOpen={setAlertOpen} />
      {dupliquedProduct && (
        <DupliqueProduct
          id={dupliquedProduct.id}
          title={dupliquedProduct.title}
          openDupliqueDialog={openDupliqueDialog}
          setOpenDupliqueDialog={setOpenDupliqueDialog}
        ></DupliqueProduct>
      )}
      {selectedItems && (
        <DeleteProductMultiple
          isLoading={loading}
          product_ids={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteProductMultiple>
      )}
      {publishDraftData?.data !== "success" && publishDraftData?.data && (
        <ProductPublishAlert
          isLoading={loading}
          products={publishDraftData?.data}
          openPublishProduct={openPublishModalAlert}
          setOpenPublishProduct={setOpenPublishModalAlert}
          onSubmit={() => {}}
        ></ProductPublishAlert>
      )}
      {selectedItems && (
        <PublishProduct
          isLoading={loading}
          product_ids={selectedItems}
          openDeleteMultipleDialog={openPublishModal}
          setOpenDeleteMultipleDialog={setOpenPublishModal}
          onSubmit={(e: any) => {
            e.preventDefault();
            publishDraftHandler(e, 2);
            openPublishDialogStatusAlert();
            setOpenPublishModal(false);
          }}
        ></PublishProduct>
      )}
      {selectedItems && (
        <DraftProduct
          isLoading={loading}
          product_ids={selectedItems}
          openDeleteMultipleDialog={openDraftModal}
          setOpenDeleteMultipleDialog={setOpenDraftModal}
          onSubmit={(e: any) => publishDraftHandler(e, 1)}
        ></DraftProduct>
      )}

      {isScannerModalOpen && (
        <BarcodeScanner
          isOpen={isScannerModalOpen}
          onClose={handleCloseScannerModal}
          onConfirm={handleConfirmBarcode}
        />
      )}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="status-modal-title"
        aria-describedby="status-modal-description"
        BackdropProps={{
          sx: {
            backdropFilter: "blur(2px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #ddd",
            boxShadow: 10,
            p: 3,
            borderRadius: "8px",
            maxWidth: "400px",
            width: "100%",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            id="status-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Seuil de Stock : {selectedStockAlert}
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TableContainer component={Paper}>
              <TT stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Point de Vente</TableCell>
                    <TableCell>Quantité</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProductPointsOfSale?.map(
                    (point: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{point.name}</TableCell>
                        <TableCell> {point.pivot.quantity}</TableCell>
                        <TableCell
                          style={{
                            color:
                              point.pivot.quantity > stockAlert
                                ? "green"
                                : "red",
                          }}
                        >
                          {point.pivot.quantity > stockAlert
                            ? "En Stock"
                            : "En Rupture"}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </TT>
            </TableContainer>
          </Typography>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}
export default Product;
