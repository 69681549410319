import { useState } from "react";
import { FaExchangeAlt, FaPen, FaTrash } from "react-icons/fa";
import Pagination from "UpdatedComponents/Pagination";
import { IoSearch } from "react-icons/io5";
import { useQuery } from "@tanstack/react-query";
import { getOrderHistories } from "UpdatedFeatures/api";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import FilterButtons from "./FilterButtons";
import { Puff } from "react-loader-spinner";
import Status from "./Status";
import UserInformationModal from "./UserInformationModal";
import ProductDetailsModal from "./ProductDetailsModal";
import ChangeStatusModal from "./ChangeStatusModal";
import TypesTabs from "./TypeTabs";
import { RiFilePaper2Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import Reciept from "./Reciept"

export default function OrdersTable() {
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openUserInformation, setOpenUserInformation] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecieptOpen, setRecieptOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [order, setOrder] = useState([]);
  // Inside your OrdersTable component
  const [selectedOption, setSelectedOption] = useState(0);
  const type = selectedOption;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const token = localStorage.getItem("token");
  const status = selectedFilter?.value;
  console.log(selectedFilter);
  const {
    data: Orders,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["orderHistories", page, search, type, status],
    queryFn: () => getOrderHistories(page, perPage, search, type, status),
    keepPreviousData: true,
  });
  console.log("uu", Orders);
  const totalPages = Math.ceil((Orders?.total || 0) / perPage);

  const handleOpenReciept = (order) => {
    setSelectedOrders(order);
    setRecieptOpen(true);
  };

  const handleCloseReciept = () => {
    setRecieptOpen(false);
  };
  const handleSaveUserInformation = (user) => {
    setUser(user);
    setOpenUserInformation(true);
  };
  const handleSaveProductInformation = (order) => {
    setOrder(order);
    setIsModalOpen(true);
  };
  const handleSelect = (id) => {
    setSelectedOrders((prev) => {
      if (prev.includes(id)) {
        return prev.filter((selectedId) => selectedId !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const handleOpenChangeStatus = (order) => {
    setOpenChangeStatusModal(true);
    setOrder(order);
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };
  const getProductCount = (products) => {
    return products ? Object.keys(products).length : 0;
  };
  return (
    <div className="w-full">
      <TypesTabs
        currentTab={selectedOption}
        setCurrentTab={setSelectedOption}
      />
      <div className="sm:flex sm:items-start justify-between">
        <div className="relative w-1/3">
          <form className="flex items-center">
            <input
              type="text"
              placeholder="Rechercher..."
              className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
              value={search}
              onChange={handleSearchChange}
            />

            <button
              type="submit"
              className="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <IoSearch color="purple" />
            </button>
          </form>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex sm: flex-col items-center gap-5">
          <FilterButtons
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            selectedOption={selectedOption}
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center mx-auto">
          <Puff
            visible={true}
            height="150"
            width="150"
            color="#895a7c"
            ariaLabel="puff-loading"
          />
        </div>
      ) : isError ? (
        <div className="text-red-500 text-center">
          Erreur de chargement des commandes.
        </div>
      ) : (
        <>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-[#b269ae]">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 text-left text-sm font-normal text-gray-100"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-normal text-gray-100"
                        >
                          Nom du Client
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-normal text-gray-100"
                        >
                          Produits
                        </th>

                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-normal text-gray-100"
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-normal text-gray-100"
                        >
                          Statut
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 text-left text-sm font-normal text-gray-100"
                        >
                          Date de Création
                        </th>
                        {selectedOption == 1 ? (
                          <>
                            <th
                              scope="col"
                              className="py-3.5 text-left text-sm font-normal text-gray-100"
                            >
                              Date de retrait
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 text-left text-sm font-normal text-gray-100"
                            >
                              Point de vente
                            </th>
                          </>
                        ) : null}
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Modifier</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 bg-white">
                      {Orders?.data.map((order) => {
                        // Ensure that `order.products` is defined before accessing its values
                        const productCount = getProductCount(order.products);

                        return (
                          <tr key={order.id}>
                            <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 ">
                              #{order.id}
                            </td>
                            <td
                              className="whitespace-nowrap py-4 text-sm text-gray-500 hover:text-gray-950 hover:scale-105 duration-200 hover:cursor-pointer"
                              onClick={() =>
                                handleSaveUserInformation(order.user)
                              }
                            >
                              {order.user.first_name} {order.user.last_name}
                            </td>
                            <td className="whitespace-nowrap py-4 text-sm text-gray-500 truncate flex items-center gap-3">
                              {productCount} Produits
                              <BsFillArrowUpRightCircleFill
                                size={22}
                                onClick={() =>
                                  handleSaveProductInformation(order)
                                }
                                className="text-[#b269ae] hover:scale-105 duration-200 cursor-pointer"
                              />
                            </td>

                            <td className="whitespace-nowrap py-4 pr-4 text-sm text-gray-500">
                              {order.total_ttc} €
                            </td>
                            <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                              {order.status === 1 ? (
                                <Status
                                  text="En cours de livraison"
                                  color="yellow"
                                />
                              ) : order.status === 2 ? (
                                <Status text="Livré" color="green" />
                              ) : order.status === 3 ? (
                                <Status text="Annulé" color="red" />
                              ) : order.status === 4 ? (
                                <Status
                                  text="Paiement en attente"
                                  color="yellow"
                                />
                              ) : order.status === 0 ? (
                                <Status
                                  text="En cours de traitement"
                                  color="yellow"
                                />
                              ) : order.status === 5 ? (
                                <Status text="Échec de paiement" color="red" />
                              ) : order.status === 6 ? (
                                <Status text="Nouvelle commande" color="red" />
                              ) : order.status === 7 ? (
                                <Status
                                  text="Payée et en attente de retrait"
                                  color="red"
                                />
                              ) : order.status === 8 ? (
                                <Status text="Retiré" color="red" />
                              ) : null}
                            </td>

                            <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                              {new Date(order.created_at).toLocaleString(
                                "fr-FR",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                }
                              )}
                            </td>
                            {selectedOption == 1 ? (
                              <>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                                  {new Date(
                                    `${order.date}T${order.time}`
                                  ).toLocaleString("fr-FR", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}
                                </td>
                                <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                                  {order.point_of_sale?.name || "—"}
                                </td>
                              </>
                            ) : null}
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center gap-4 h-full">
                              <button
                                type="button"
                                className="text-[#b269ae] hover:text-[#b269ae] hover:scale-105 duration-150 "
                                onClick={() => handleOpenChangeStatus(order)}
                              >
                                <FaExchangeAlt size={16} />
                              </button>
                              <button
                                type="button"
                                className="text-[#b269ae] hover:text-[#b269ae] hover:scale-105 duration-150 "
                                onClick={() => handleOpenReciept({ order })}
                              >
                                <RiFilePaper2Fill size={16} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ProductDetailsModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            order={order}
          />
          <UserInformationModal
            setOpen={setOpenUserInformation}
            open={openUserInformation}
            user={user}
          />
          <ChangeStatusModal
            open={openChangeStatusModal}
            setOpen={setOpenChangeStatusModal}
            order={order}
            type={type}
          />
          {isRecieptOpen && (
            <Reciept
              open={isRecieptOpen}
              close={handleCloseReciept}
              order={selectedOrders}
            />
          )}
        </>
      )}
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        nextPage={nextPage}
        prevPage={prevPage}
        setPage={setPage} // Pass the setPage function
      />
      {/* Add modals for adding/editing orders */}
    </div>
  );
}
