import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  SnackBarActions,
  SnackBarState,
} from "redux/slices/SnackBar/SnackBarSlice";

export declare type CustomError = {
  data: {
    error: string[];
    errors: {};
  };
  status: number;
};

export const useSnackBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const displaySuccessSnackBar = (message: string) => {
    dispatch(
      SnackBarActions.showSnackBar({
        type: "success",
        message: message,
      })
    );
  };

  const displayErrorSnackBar = (message: string) => {
    dispatch(
      SnackBarActions.showSnackBar({
        type: "error",
        message: message,
      })
    );
  };

  const displayErrorApiSnackBar = (
    error: CustomError | FetchBaseQueryError | SerializedError
  ) => {
    if (error) {
      if (
        (error as CustomError).data &&
        (error as CustomError).data.error &&
        (error as CustomError).data.error.length > 0
      ) {
        const firstError = (error as CustomError).data.error[0];
        displayErrorSnackBar(t(firstError));
      } else if ("originalStatus" in error) {
        const status = "originalStatus" in error ? error.originalStatus : null;
        if (status === 401) {
          displayErrorSnackBar(t("common.unauthorized"));
        }
      } else if ("status" in error) {
        // you can access all properties of `FetchBaseQueryError` here
        const errMsg =
          "error" in error ? error.error : JSON.stringify(error.data);
        displayErrorSnackBar(errMsg);
      }
    }
  };

  const displaySnackBar = (notification: SnackBarState) => {
    dispatch(SnackBarActions.showSnackBar(notification));
  };
  const clearSnackBar = () => {
    dispatch(SnackBarActions.clearSnackBar());
  };
  return {
    displaySnackBar,
    clearSnackBar,
    displaySuccessSnackBar,
    displayErrorSnackBar,
    displayErrorApiSnackBar,
  } as const;
};
