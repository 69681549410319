import { useTranslation } from "react-i18next";
import { useCallback, useState, useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./AddTax.style";
import {
  ITaxAddDialog,
  ITaxAddingData,
  ITaxesAddingDataError,
} from "./AddTax.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { useAddTaxMutation } from "redux/api/tax/taxes";
import countries from "./countries.json"; // Import countries.json

function AddTax({ openAddingDialog, setOpenAddingDialog }: ITaxAddDialog) {
  const { t } = useTranslation();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [addingData, setAddingData] = useState<ITaxAddingData>({
    name: "",
    percentage: "",
    country: "",
  });

  const [addingError, setAddingError] = useState<ITaxesAddingDataError>({
    name: "",
    percentage: "",
    country: "",
  });

  const [addingTax, { data, error, isLoading, reset }] = useAddTaxMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("tax.successfully_adding"));
    reset();
  }

  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingData({ name: "", percentage: "", country: "" });
    setAddingError({ name: "", percentage: "", country: "" });
  }, [setOpenAddingDialog]);

  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    if (Object.values(addingError).every((err) => err === "")) {
      addingTax(addingData);
      closeAddingDialog();
    }
  };

  useEffect(() => {
    console.log("addingData", addingData);
  }, [addingData]);

  const onChange = useCallback(
    (field: string) =>
      (value: string) => {
        setAddingData((prevData) => ({
          ...prevData,
          [field]: value,
        }));

        if (field === "country" && value === "") {
          setAddingError((prevError) => ({
            ...prevError,
            country: "Pays obligatoire",
          }));
        } else {
          setAddingError((prevError) => ({
            ...prevError,
            country: "",
          }));
        }
      },
    []
  );

  return (
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("tax.add")}
    >
      <form onSubmit={onSuccessAddingDialog}>
        <InputStyle>
          <TextInput
            value={addingData.name}
            label={t("tax.name")}
            onChange={(e) => onChange("name")(e.target.value)}
            error={t(addingError.name)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.percentage}
            label={t("tax.percentage")}
            type="number"
            onChange={(e) => onChange("percentage")(e.target.value)}
            error={t(addingError.percentage)}
          />
        </InputStyle>
        <InputStyle>
          <FormControl
            fullWidth
            error={!!addingError.country}
          >
            <InputLabel>{t("tax.country")}</InputLabel>
            <Select
              value={addingData.country}
              onChange={(e) => onChange("country")(e.target.value)}
              label={t("tax.country")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, 
                  },
                },
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.name}>
                  {country.emoji} {country.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {addingError.country && t(addingError.country)}
            </FormHelperText>
          </FormControl>
        </InputStyle>



        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}

export default AddTax;
