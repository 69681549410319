import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { ICategoryData } from "./Category.types";
import { Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
  CategoryImageStyle,
} from "./Category.style";
import { Add, Delete, Edit } from "@mui/icons-material";
import useCategoryList from "./useCategoryList";
import { CONFIG } from "config/config";
import { useCallback, useState } from "react";
import DeleteCategory from "../CategoryDelete/DeleteCategory";
import AddCategory from "../CategoryAdd/AddCategory";
import EditCategory from "../CategoryEdit/EditCategory";
import { Link } from "react-router-dom";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import AddSubCategory from "features/SubCategory/SubCategoryAdd/AddSubCategory";
import { useDeleteMultipleCategoriesMutation } from "redux/api/category/category";
import DeleteCategoryMultiple from "../CategoryDeleteMultiple/DeleteCategoryMultiple";
import { useSnackBar } from "hooks/useSnackBar";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateCategoryOrder } from "UpdatedFeatures/api";
import { toast } from "react-toastify";


function Category() {
  const { t } = useTranslation();
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    categoryData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  } = useCategoryList();
  const [category, setCategory] = useState<ICategoryData>({
    id: 0,
    order: 0,
    title: "",
    image: "",
    created_at: "",
    updated_at: ""
  })
  const [openDeleteMultipleModal, setOpenDeleteMultipleModal] = useState(false)
  const [orderChanges, setOrderChanges] = useState<{[key: number]: number}>({});

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedCategory, setDeletedCategory] = useState<{
    id: number;
    title: string;
  }>();
  const openDeleteMultipleDialogStatus = () => {
    setOpenDeleteMultipleModal(true);

  };
  const [isAddSubCat, setIsAddSubCat] = useState(false)

  const queryClient = useQueryClient();

  const mutation = useMutation<
    { data: any },
    Error,
    { categoryId: number; newOrder: number }
  >({
    mutationFn: ({ categoryId, newOrder }) => updateCategoryOrder({ categoryId, newOrder }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['format'] });
      toast.success('La catégorie a été mise à jour avec succès !');
    },
    onError: () => {
      toast.error('Erreur lors de la mise à jour de la catégorie.');
    },
  });
  const handleOrderChange = (categoryId: number, newOrder: number) => {
    mutation.mutate({ categoryId, newOrder });
  }

  const openDeleteDialogStatus = useCallback(
    (id: number, title: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCategory({ id: id, title: title });
    },
    [setOpenDeleteDialog, setDeletedCategory]
  );
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [deleteCode, { data, error, reset, isLoading: loading }] =
    useDeleteMultipleCategoriesMutation();


  const deleteMultiple = (event: any) => {
    event.preventDefault()
    deleteCode({
      categories_ids: selectedItems,
      select_all: selectAll,
      search: search
    })
    if (!error)
      setOpenDeleteMultipleModal(false)

  }

  const [openSubCatAddingDialog, setSubCatOpenAddingDialog] = useState<boolean>(false);

  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setIsAddSubCat(false)

    setOpenAddingDialog(true);
  }, []);
  const openAddingSubCatDialogStatus = useCallback((category: ICategoryData) => {
    setIsAddSubCat(true)

    setSubCatOpenAddingDialog(true);
    setCategory({ ...category })
  }, []);
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedCategory, setEditedCategory] = useState<{
    id: number;
    title: string;
    image: string;
  }>();

  const handleOrderInputChange = (categoryId: number, currentOrder: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOrder = parseInt(e.target.value);
    if (newOrder === currentOrder) {
      const newChanges = {...orderChanges};
      delete newChanges[categoryId];
      setOrderChanges(newChanges);
    } else {
      setOrderChanges({
        ...orderChanges,
        [categoryId]: newOrder
      });
    }
  };

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("promotion_codes.successfully_deleted"));
    reset();

  }
  const openEditingDialogStatus = useCallback(
    (id: number, title: string, image: string) => () => {
      setEditedCategory({ id, title, image });
      setOpenEditingDialog(true);
    },
    []
  );

  return (
    <DashboardLayout
      title={t("category.page_title")}
      selectedPath={RouteIdEnum.Categories}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <ButtonContainerStyle item xs={12} sm={4}>
          {((selectAll || selectedItems.length != 0)) && <DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
          }
          &nbsp;
          <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "title",
            title: t("category.title"),
            onClick: onOrderChange("title"),
            asc: orderBy === "title" ? orderDir === "asc" : undefined,
          },
          {
            key: "image",
            title: t("category.image"),
          },
          {
            key: "created_at",
            title: t("category.created_at"),
          },
          { key: "action", title: t("common.actions") },
          { key: "rank", title: "Rank" },
        ]}
        bodyData={categoryData?.data.map((e: ICategoryData) => {
          return {
            checkbox: (
              <Checkbox checked={(!selectAll && selectedItems.includes(e.id)) || (selectAll && !selectedItems.includes(e.id))}
                onChange={() => handleCheckboxChange(e.id)}
              />
            ),
            title: <Link to={`/subcategories/${e.id}`}> {e.title}</Link>,
            image: (
              <p>
                {
                  <CategoryImageStyle
                    src={CONFIG.BASE_URL + e.image}
                  ></CategoryImageStyle>
                }
              </p>
            ),
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(e.id, e.title, e.image)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.title)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.add")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() => openAddingSubCatDialogStatus(e)}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
            rank: (
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  value={orderChanges[e.id] ?? e.order}
                  onChange={handleOrderInputChange(e.id, e.order)}
                  className="p-2 border w-fit border-purple-300 focus:border-purple-400 focus:ring-offset-1 focus:ring-offset-1-offset-purple-400 rounded-md"
                />
                {orderChanges[e.id] !== undefined && (
                  <button
                    onClick={() => {
                      handleOrderChange(e.id, orderChanges[e.id]);
                      const newChanges = {...orderChanges};
                      delete newChanges[e.id];
                      setOrderChanges(newChanges);
                    }}
                    className="p-1 bg-[#b269ae] text-xs text-white rounded-md"
                  >
                    Sauvegarder 
                  </button>
                )}
              </div>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: categoryData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {deletedCategory && (
        <DeleteCategory
          id={deletedCategory.id}
          title={deletedCategory.title}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteCategory>
      )}
      {
        !isAddSubCat ? <AddCategory
          openAddingDialog={openAddingDialog}
          setOpenAddingDialog={setOpenAddingDialog}
        ></AddCategory> :
          <AddSubCategory
            category={category}
            openSubCatAddingDialog={openSubCatAddingDialog}
            setOpenSubCatAddingDialog={setSubCatOpenAddingDialog}
          ></AddSubCategory>
      }
      {
        selectedItems && (
          <DeleteCategoryMultiple
            isLoading={loading}
            catesgs={selectedItems}
            openDeleteMultipleDialog={openDeleteMultipleModal}
            setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
            onSubmit={deleteMultiple}
          ></DeleteCategoryMultiple>
        )
      }
      {
        editedCategory && (
          <EditCategory
            iniData={{
              id: editedCategory.id,
              title: editedCategory.title,
              image: editedCategory.image,
            }}
            openEditingDialog={openEditingDialog}
            setOpenEditingDialog={setOpenEditingDialog}
          ></EditCategory>
        )
      }
    </DashboardLayout >
  );
}
export default Category;
