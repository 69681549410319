import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function StockManagementTable() {
    const [search, setSearch] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [dummyData, setDummyData] = useState([
        {
            id: 1,
            date: "2024-12-20",
            stock: "Stock A",
            fournisseur: "Fournisseur A",
            libelle: "Produit 1",
            quantite: 100,
            montant: "500 €",
            datePaiement: "2024-12-22",
            statut: "Payé",
        },
        {
            id: 2,
            date: "2024-12-19",
            stock: "Stock B",
            fournisseur: "Fournisseur B",
            libelle: "Produit 2",
            quantite: 50,
            montant: "250 €",
            datePaiement: "2024-12-23",
            statut: "En attente",
        },
    ]);

    const handleSearchChange = (e) => setSearch(e.target.value);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        setSearchTerm(search);
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-12">
            <div className="sm:flex sm:items-center justify-between">
                <div className="relative w-1/3">
                    <form onSubmit={handleSearchSubmit} className="flex items-center">
                        <input
                            type="text"
                            placeholder="Rechercher..."
                            value={search}
                            onChange={handleSearchChange}
                            className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
                        />
                        <button type="submit" className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <IoSearch color="purple" />
                        </button>
                    </form>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 flex items-center gap-3">
                    <Link
                        to={"/gestion-stock/add"}
                        type="button"
                        className="flex items-center justify-center px-3 py-2 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
                    >
                        <span className="text-sm">+ Ajouter Arrivage</span>
                    </Link>
                </div>
            </div>

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-[#b269ae]">
                                    <tr>
                                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-100 sm:pl-6">
                                            Date
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Stock
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Fournisseur
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Libellé
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Quantité
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Montant
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Date de paiement
                                        </th>
                                        <th className="px-3 py-3.5 text-left text-sm font-normal text-gray-100">
                                            Statut
                                        </th>
                                        <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {dummyData.map((item) => (
                                        <tr key={item.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {item.date}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {item.stock}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {item.fournisseur}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {item.libelle}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {item.quantite}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {item.montant}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {item.datePaiement}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {item.statut}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <button className="text-[#b269ae] hover:text-purple-300">
                                                    <FaPen />
                                                </button>
                                                <button className="text-[#b269ae] hover:text-purple-300 ml-4">
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
