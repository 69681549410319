import React, { useCallback } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import Dialog from "components/common/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import { useGetCategoriesValuesExpeditionModeQuery } from "redux/api/delivery/expeditionMode/expeditionMode";
import { IDialog } from "./ExpeditionCategories.types";
import { IExpeditionCategoriesData } from "./ExpeditionCategories.types";

function ExpeditionCategories({
  openAddingDialog,
  setOpenAddingDialog,
  selectedElement,
  resetSelectedItem,
}: IDialog) {
  const { t } = useTranslation();

  const { data: categValue, isLoading } =
    useGetCategoriesValuesExpeditionModeQuery({
      id: selectedElement,
    });

  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    resetSelectedItem();
  }, [setOpenAddingDialog, resetSelectedItem]);

  return (
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("category.list")}
    >
      {isLoading ? (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  {t("Category Name")}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  {t("Category Value")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categValue?.map((e: IExpeditionCategoriesData) => (
                <TableRow key={e?.category_shipping?.id}>
                  <TableCell align="left">
                    {e?.category_shipping?.name}
                  </TableCell>
                  <TableCell align="center">{e.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Dialog>
  );
}

export default ExpeditionCategories;
