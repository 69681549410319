export const menu = {
  clients: "Clients",
  promotion_codes: "Codes Promotions",
  categories: "Catégories",
  products: "Produits",
  order_history: "Historique des commandes",
  supplier: "Fournisseurs",
  delivery: "Livraison",
  cat_exped: "Catégories d'expedition",
  mode_exped: "Modes d'expédition",
  tax: "Taxes",
  promo_flash: "Promotion Flash",
  stock_plus: "Stock +",
  dashboard: "Tableau de bord",
  roles: "Gestion des Roles",
  advanced_stat: "Statistiques avancées",
  stat_products: "Statistiques Produits",
  stat_shipping_mode: "Statistiques Mode d'expédition",
  brands: "Marques",
  stats_visits: "Statistiques de visiteurs",
};
