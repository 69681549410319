import { RouteIdEnum } from "config/enums";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "utils/services/storage.service";

interface PrivateRouteInterface {
  element: JSX.Element;
  requiredRoles: string;
}

const PrivateRoute = ({ element, requiredRoles }: PrivateRouteInterface) => {

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  console.log('dfghjklm',userData);

  const userPermissions = userData?.role == 4 ? ["order_history"] : userData?.roles?.functionality || [];
  const extraRoles = userData?.extras || [];
  const combinedPermissions = userData.role === 4 ? ["order-history"] : [...userPermissions, ...extraRoles];
 
  console.log('dfghddddjklm',combinedPermissions);

  const isSuperAdmin = false ;
  const hasRequiredRole = isSuperAdmin || combinedPermissions.includes(requiredRoles); // Allow access if super admin or has required role
  console.log(hasRequiredRole);
  console.log(combinedPermissions[0]);


  return isLoggedIn() ? (
    hasRequiredRole ? (
      element
    ) : combinedPermissions ? (
      <Navigate to={`/${combinedPermissions[0]}`} replace />
    ) : (
      <Navigate to={`/${RouteIdEnum.Any}`} replace />
    )
  ) : (
    <Navigate to={`/${RouteIdEnum.Login}`} replace />
  );
  
  
  
};

export default PrivateRoute;
