import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { Puff } from "react-loader-spinner";
import { deleteRole } from "../api";
import DeleteRoleConfirmationModal from "./DeleteRoleConfirmationModal";
import {
  CreateRole,
  EditStockPlus,
  PaginateRoles,
  updateRole,
} from "UpdatedFeatures/api";
import { toast } from "react-toastify";
import AddRoleModal from "./AddRoleModal";
import EditRoleModal from "./EditRoleModal";
import ImportExport from "components/common/ImportExport";

export default function RolesTable() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [role, setRole] = useState(null);
  const queryClient = useQueryClient();

  const {
    data: Roles,
    isLoading: rolesLoading,
    isError: rolesError,
  } = useQuery({
    queryKey: ["PaginateRoles", page, perPage, search],
    queryFn: () => PaginateRoles(page, perPage, search),
    enabled: !!search || search === "",
  });
  console.log(Roles);

  const createMutation = useMutation({
    mutationFn: ({ data }) => CreateRole({ data }),
    onSuccess: () => {
      queryClient.invalidateQueries(["PaginateRoles", page, perPage]);
      setOpenAddModal(false);
      toast.success("Le Role a été ajouté !");
    },
    onError: (error) => {
      toast.error(`Erreur lors de l'ajout du Role : ${error.message}`);
      setOpenAddModal(false);
    },
  });
  const editMutation = useMutation({
    mutationFn: ({ ID, data }) => updateRole(ID, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["PaginateRoles", page, perPage]);
      setOpenEditModal(false);
      toast.success("Le Role a été modifié !");
    },
    onError: (error) => {
      console.log(error);
      toast.error(
        `Erreur lors de la modification du Role : ${error.response.data.error}`
      );
      setOpenEditModal(false);
    },
  });
  const deleteMutation = useMutation({
    mutationFn: (roleId) => deleteRole(roleId),
    onSuccess: () => {
      queryClient.invalidateQueries(["PaginateRoles", page, perPage,search]);
      setIsModalOpen(false);
      setRoleToDelete(null);
      toast.success("Le role a été supprimé !");
    },
    onError: (error) => {
      console.error("Error deleting role:", error);
      toast.error(
        `Erreur lors de la suppression du role : ${
          error.response?.data?.error || error.message
        }`
      );
    },
  });
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(search);
  };
  const handleEditRole = (role) => {
    setRole(role);
    setOpenEditModal(true);
  };

  const openDeleteModal = (role) => {
    setRoleToDelete(role);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    if (roleToDelete) {
      deleteMutation.mutate(roleToDelete.id);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setRoleToDelete(null);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-12">
      <AddRoleModal
        open={openAddModal}
        setOpen={setOpenAddModal}
        mutation={createMutation}
      />
      <EditRoleModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        data={role}
        mutation={editMutation}
      />
      {/* Main StockPlus Table */}
      <div className="sm:flex sm:items-center justify-between">
        <div className="relative w-1/3">
          <form onSubmit={handleSearchSubmit} className="flex items-center">
            <input
              type="text"
              placeholder="Rechercher..."
              value={search}
              onChange={handleSearchChange}
              className="block w-full p-3 pl-10 text-sm border border-[#b269ae] rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-300"
            />
            <button
              type="submit"
              className="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <IoSearch color="purple" />
            </button>
          </form>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 flex justify-center items-center gap-3">
          <ImportExport />
          <button
            onClick={() => setOpenAddModal(true)}
            type="button"
            className="flex items-center justify-center px-3 py-1 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
          >
            <span className="text-sm">+ Ajouter</span>
          </button>
        </div>
      </div>
      {/* Static Roles and Permissions Table */}
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg my-8">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-[#b269ae]">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6"
              >
                Role
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-100"
              >
                Permissions
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-100"
              />
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rolesLoading ? (
              <tr>
                <td colSpan="2" className="text-center py-4">
                  <Puff
                    visible={true}
                    height="50"
                    width="50"
                    color="#895a7c"
                    ariaLabel="puff-loading"
                  />
                </td>
              </tr>
            ) : rolesError ? (
              <tr>
                <td colSpan="2" className="text-center py-4">
                  Error: {rolesError.message}
                </td>
              </tr>
            ) : (
              Roles?.data.map((role) => (
                <tr key={role.id}>
                  <td className="px-3 py-4 text-sm text-gray-900">
                    {role.name}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {role.functionality.join(", ")}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="flex space-x-4">
                      <button
                        className="text-[#b269ae] hover:text-purple-300"
                        onClick={() => handleEditRole(role)}
                      >
                        <FaPen size={18} />
                      </button>
                      <button
                        className="text-[#b269ae] hover:text-purple-300"
                        onClick={() => openDeleteModal(role)}
                      >
                        <FaTrash size={18} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
            {isModalOpen && (
              <DeleteRoleConfirmationModal
                open={isModalOpen}
                onConfirm={confirmDelete}
                onCancel={closeModal}
                isLoading={deleteMutation.isLoading}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
