import { Divider, List, ListItem, styled } from "@mui/material";
import { DRAWER_WIDTH_MAX, ListeItemPropsInterface } from "./Menu.type";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { APP_BAR_HEIGHT } from "../AppBar/AppBar.type";

export const MenuContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100%",
  overflowX: "hidden",
  marginTop: APP_BAR_HEIGHT,
  background: theme.palette.primary.main,
  "scrollbar-width": "none",
  "&::-webkit-scrollbar": {
    width: 1,
  },
}));

export const MenuStyle = styled("div")(({ theme }) => ({
  overflowX: "hidden",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: DRAWER_WIDTH_MAX,
  height: "100%",
  transform: `translateX(0px)`,
  overflow: "auto",
  [theme.breakpoints.down(DRAWER_WIDTH_MAX)]: {
    width: `100vw`,
    transform: `translateX(0px)`,
  },
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const ListStyle = styled(List)(({ theme }) => ({
  padding: "0px !important",
  flexDirection: "column",
  width: DRAWER_WIDTH_MAX,
}));

export const ListItemStyle = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "5px !important",
  marginBottom: "5px !important",
  padding: "0px !important",
  textDecoration: "none",
  height: 40,
  cursor: "pointer",
  "&:hover": {
    background:"linear-gradient(to right, #9433B3, #ebd478)",
    "&>h1": {
      left: 10,
      transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
}));

export const ExpandedContentStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  paddingRight: 20,
}));

export const ExpandedSubContentStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const IconImageStyle = styled("img")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const LiTitleStyle = styled("h1")(({ theme }) => ({
  position: "relative",
  left: 0,
  color: "white",
  fontSize: 14,
  marginLeft: 7,
  transition: theme.transitions.create("left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const LiIconExpandLessStyle = styled(ExpandLess)(({ theme }) => ({
  color: "#FFFFFF", 
  marginLeft: "10px",
  fontSize: "30px", 
}));

export const LiIconExpandMoreStyle = styled(ExpandMore)(({ theme }) => ({
  color: "#FFFFFF", 
  marginLeft: "10px",
  fontSize: "30px", 
}));


export const DividerStyle = styled(Divider)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const ListItemSelectedStyle = styled(ListItem)<ListeItemPropsInterface>(
  ({ theme, isselected }) => ({
    display: "flex",
    alignItems: "center",
    marginTop: "5px !important",
    marginBottom: "5px !important",
    padding: "0px !important",
    textDecoration: "none",
    cursor: "pointer",
    height: 40,
    background: isselected
      ? "linear-gradient(to right, #9433B3, #ebd478)"
      : "",
    "&:hover": {
       background:"linear-gradient(to right, #ebd478, #9433B3)",
      "&>h1": {
        left: 10,
        transition: theme.transitions.create("left", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  })
);
