import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import MultiSelectDropdown from "UpdatedFeatures/Vendeurs/MultiSelect";
import { extraOptions } from "UpdatedFeatures";
const EditRoleModal = ({ open, setOpen, mutation, data }) => {
    const [formData, setFormData] = useState({
        id: null,
        name: "",
        functionality: [],
    });
    const [selectedOptions, setSelectedOptions] = useState(data?.functionality || []);

    useEffect(() => {
        if (data) {
            setFormData({
                id: data.id || "",
                name: data.name || "",
                functionality: data.functionality || [],
            });
            setSelectedOptions(data.functionality || []);
        }
    }, [data]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData((prevData) => ({
            ...prevData,
            functionality: selectedOptions,
        }));
        mutation.mutate({ ID: formData.id, data: { ...formData, functionality: selectedOptions } });
    };



    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50" style={{ zIndex: 9999, overflow: "visible" }} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            {/* Overlay */}
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75" aria-hidden="true" onClick={() => setOpen(false)} />

            {/* Modal Content */}
            <div className="flex items-center justify-center min-h-screen p-4">
                <div className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden" onClick={(e) => e.stopPropagation()}>
                    {/* Header */}
                    <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
                        <h3 id="modal-title" className="text-lg font-medium leading-6 text-white">
                            Modifier un Role
                        </h3>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
                            onClick={() => setOpen(false)}
                        >
                            <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4 p-6">
                        <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-4 mt-8">
                            {/* Name Input */}
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Nom
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                                />
                            </div>

                            {/* MultiSelectDropdown */}
                            <MultiSelectDropdown
                                options={extraOptions}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                            />
                        </div>

                        {/* Submit Button */}
                        <div className="mt-6 flex justify-end gap-6">
                            <button
                                type="submit"
                                className="block rounded-md bg-[#b269ae] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                            >
                                Modifier
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditRoleModal;
