import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';

const MultiSelectDropdown = ({ options, selectedOptions = [], setSelectedOptions }) => {
    console.log(selectedOptions)
    useEffect(() => {
        if (!selectedOptions) {
            setSelectedOptions([])
        }
    }, [selectedOptions, setSelectedOptions])

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (option) => {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(selectedOptions.filter(item => item !== option));
        } else {
            setSelectedOptions([...selectedOptions, option]);
        }
        setIsOpen(!isOpen);
    };

    const handleRemove = (option, e) => {
        e.stopPropagation();
        setSelectedOptions(selectedOptions.filter(item => item !== option));
    };


    return (
        <div className="relative" style={{ zIndex: 999999 }}>
            {/* Label for the dropdown */}
            <label className="block text-sm font-medium text-gray-700 mb-2">
                Choisissez des extra permissions
            </label>

            <div
                className={`flex flex-wrap items-center border rounded-md p-2 cursor-pointer overflow-auto transition duration-200 
          ${isOpen ? 'border-[--secondaryColor] ring-2 ring-[--secondaryColor]' : 'border-gray-300'}`}
                style={{ backgroundColor: 'white' }} // Match height with the select
                onClick={toggleDropdown}
            >
                {selectedOptions?.map(option => (
                    <span
                        key={option}
                        className="bg-[#b06bae] text-white text-xs rounded-full px-2 py-1 mr-2 mb-1 flex items-center transition gap-1 duration-200"
                    >
                        {option}
                        <button onClick={(e) => handleRemove(option, e)} className="ml-1 relative  text-white" >
                            <IoClose color='white' size={17} />
                        </button>
                    </span>
                ))}
                <span className="text-gray-500">{selectedOptions.length === 0 ? 'Select options' : ''}</span>
            </div>
            {isOpen && (
                <div className=" w-full mt-1 bg-white border rounded-md shadow-lg max-h-32 overflow-y-auto " style={{ zIndex: 9999999 }} >
                    {options.map(option => (
                        <div
                            key={option}
                            className={`cursor-pointer p-2 transition duration-200 hover:bg-[#b06bae] hover:text-white ${selectedOptions.includes(option) ? 'bg-[#b06bae] text-white' : 'text-gray-800'}`}
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
