import { CleanHands } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { RiCloseLine } from "react-icons/ri";
import { getPointOfSales, getRoles } from "UpdatedFeatures/api";
import MultiSelectDropdown from "./MultiSelect";
import { extraOptions } from "UpdatedFeatures";
const EditSeller = ({
  open,
  setOpen,
  formData,
  setFormData,
  mutation,
  seller,
  currentTab
}) => {
  const [selectedID, setSelectedId] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  console.log(seller)
  useEffect(() => {
    if (open && seller) {
      setSelectedOptions(seller.extras || [])
      setFormData({
        first_name: seller.first_name || "",
        last_name: seller.last_name || "",
        email: seller.email || "",
        phone: seller.phone || "",
        gender: seller.gender || "",
        birth: seller.birth ? new Date(seller.birth * 1000).toISOString().split("T")[0] : "",
        role: seller.role,
        role_id: seller.roles?.id,
        point_of_sale_id: seller.point_of_sale_id || "",
        extra: seller.extras || []
      });
      setSelectedId(seller.point_of_sale_id || "");
    }
  }, [open, seller, setFormData]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      extra: selectedOptions,
    }));
  }, [selectedOptions])

  console.log(formData)
  const token = localStorage.getItem("token");
  console.log(selectedOptions)
  const handleChange = (e) => {
    const { name, value } = e.target;
    if ((name === "point_of_sale_id")) {
      setSelectedId(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.id || value,
      }));
    }
    else if (name === "role_id") {
      setSelectedRole(value);
      const newValue = JSON.parse(value)
      console.log(newValue)
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue.id,
      }));
    }
    else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const {
    data: roles,
    error,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: () => getRoles(),
  });
  console.log(formData)
  const handleSubmit = (e) => {
    let dataToSend
    e.preventDefault();
    if (formData?.role == 1) {
      dataToSend = {
        ...formData,
        point_of_sale_id: null,
      };
    } else {
      dataToSend = {
        ...formData,
        point_of_sale_id: Number(formData.point_of_sale_id),
        role_id: null
      };
    }

    mutation.mutate({ seller: seller.id, data: dataToSend, token });
  };
  const { data: poss } = useQuery({
    queryKey: ["pointOfSales"],
    queryFn: () => getPointOfSales(),
    keepPreviousData: true,
  });

  const [filteredPermissions, setFilteredPermissions] = useState(extraOptions)

  useEffect(() => {
    if (!roles || !formData.role || !extraOptions) return;

    const selectedFunctionality = roles.data.find((p) => p.id === formData.role)?.functionality || [];
    console.log("Selected Functionality:", selectedFunctionality);

    const filtered = extraOptions.filter((option) => !selectedFunctionality.includes(option));
    console.log("Filtered Permissions:", filtered);

    setFilteredPermissions(filtered);
  }, [roles, formData.role]);

  const posData = poss?.data;
  console.log(roles)
  console.log(roles?.data.find((p) => p.id === formData.role))
  if (!open) return null;

  return (
    <div
      className="fixed inset-0 z-50"
      style={{ zIndex: 9999, overflow: 'visible' }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75"
        aria-hidden="true"
        onClick={() => setOpen(false)}
      />

      {/* Modal Content */}
      <div className="flex items-center justify-center min-h-screen p-4">
        <div
          className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
            <h3
              id="modal-title"
              className="text-lg font-medium leading-6 text-white"
            >
              Modifier le vendeur
            </h3>
            <button
              type="button"
              className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
              onClick={() => setOpen(false)}
            >
              <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 p-6">
            <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-4">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prénom
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={formData.first_name || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom de famille
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  value={formData.last_name || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Numéro de téléphone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>

              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Genre
                </label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                >
                  <option value="">Sélectionnez un genre</option>
                  <option value="male">Homme</option>
                  <option value="female">Femme</option>
                  <option value="other">Autre</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="birth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date de naissance
                </label>
                <input
                  id="birth"
                  name="birth"
                  type="date"
                  value={formData.birth || ""}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                />
              </div>
              {
                currentTab === 3 || currentTab === 4 ? (
                  <div>
                    <label
                      htmlFor="point_of_sale_id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Point de vente
                    </label>
                    <select
                      id="point_of_sale_id"
                      name="point_of_sale_id"
                      value={formData?.point_of_sale_id}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                    >
                      <option value="">Sélectionnez un point de vente</option>
                      {posData.map((p) => (
                        <option key={p?.id} value={p?.id}>
                          {p?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <>
                    <div>
                      <label
                        htmlFor="role_id"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Role
                      </label>
                      <select
                        id="role_id"
                        name="role_id"
                        value={JSON.stringify(roles?.data.find((p) => p.id === formData.role_id))}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-[--secondaryColor] focus:ring-[--secondaryColor] sm:text-sm h-12"
                      >
                        <option value="">Sélectionnez un role</option>
                        {roles.data.map((p) => (
                          <option key={p.id} value={JSON.stringify(p)}>
                            {p?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <MultiSelectDropdown options={filteredPermissions}
                      setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} />
                  </>
                )}
            </div>
            <div className="mt-6 flex justify-end gap-6">
              <button
                type="button"
                className="block rounded-md bg-purple-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                onClick={() => setOpen(false)}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="block rounded-md bg-[#b269ae] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
              >
                Modifier
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSeller;
