import { useState } from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

export default function Pagination({
  prevPage,
  nextPage,
  totalPages,
  setPage,
}) {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      prevPage();
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      nextPage();
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPage(pageNumber);
    // You might want to call a function here to fetch data for the specific page
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // How many pages we want to show at a time

    // Show first and last pages, plus the current page with some padding
    if (totalPages <= maxPagesToShow) {
      // If there are fewer pages than the max, show all
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        // Show first pages and last page
        pageNumbers.push(1, 2, 3, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Show last pages and first page
        pageNumbers.push(1, "...", totalPages - 2, totalPages - 1, totalPages);
      } else {
        // Show first page, current page with padding, and last page
        pageNumbers.push(1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages);
      }
    }

    return pageNumbers;
  };

  const pages = generatePageNumbers();

  return (
    <nav className="flex items-center justify-center border-t border-gray-200 px-4 sm:px-0 my-9">
      <div className="-mt-px flex w-0 flex-1">
        <a
          href="#"
          onClick={handlePrevClick}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${
            currentPage === 1
              ? "text-gray-300"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </a>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((page, index) => (
          <span key={index}>
            {page === "..." ? (
              <span className="inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium text-gray-500">...</span>
            ) : (
              <a
                href="#"
                onClick={() => handlePageClick(page)}
                className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                  currentPage === page
                    ? "border-purple-400 text-[#895a7c]"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
                aria-current={currentPage === page ? "page" : undefined}
              >
                {page}
              </a>
            )}
          </span>
        ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href="#"
          onClick={handleNextClick}
          className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${
            currentPage === totalPages
              ? "text-gray-300"
              : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </a>
      </div>
    </nav>
  );
}
