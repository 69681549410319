import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import { Grid, Box, Typography, Divider } from "@mui/material";
import { ISuppliersDialog, ISuppliersShowData } from "./SupplierShow.types";

function ShowSupplier({
  id,
  codeInfos,
  openShowDialog,
  setOpenShowDialog,
}: ISuppliersDialog) {
  const { t } = useTranslation();

  const [supplierData, setSupplierData] = useState<ISuppliersShowData>({
    company_name: codeInfos.company_name,
    address: codeInfos.address,
    postal_code: codeInfos.postal_code,
    city: codeInfos.city,
    region: codeInfos.region,
    department: codeInfos.department,
    country: codeInfos.country,
    email: codeInfos.email,
    office_phone: codeInfos.office_phone,
    mobile_phone: codeInfos.mobile_phone,
    website: codeInfos.website,
    contact_last_name: codeInfos.contact_last_name,
    contact_first_name: codeInfos.contact_first_name,
    contact_position: codeInfos.contact_position,
    contact_mobile: codeInfos.contact_mobile,
    contact_email: codeInfos.contact_email,
    contact_office_phone: codeInfos.contact_office_phone,
  });

  useEffect(() => {
    if (openShowDialog)
      setSupplierData({
        ...codeInfos,
      });
  }, [openShowDialog, codeInfos]);

  const closeEditingDialog = useCallback(() => {
    setOpenShowDialog(false);
    setSupplierData({
      company_name: "",
      address: "",
      postal_code: "",
      city: "",
      region: "",
      department: "",
      country: "",
      email: "",
      office_phone: "",
      mobile_phone: "",
      website: "",
      contact_last_name: "",
      contact_first_name: "",
      contact_position: "",
      contact_mobile: "",
      contact_email: "",
      contact_office_phone: "",
    });
  }, [setOpenShowDialog]);

  const renderField = (label: string, value: string) => (
    <Box>
      <Typography variant="subtitle2" color="primary" gutterBottom>
        {label}:
      </Typography>
      <Typography variant="body1">{value || "-"}</Typography>
    </Box>
  );

  return (
    <Dialog
      open={openShowDialog}
      handleClose={closeEditingDialog}
      title={t("supplier.show")}
    >
      <Grid container spacing={3}>
        {/* Supplier Information */}
        <Grid item xs={12}>
          <Typography variant="h6" color="secondary">
            {t("Informations sur le fournisseur")}
          </Typography>
          <Divider sx={{ mb: 2 }} />
        </Grid>

        <Grid item xs={12} sm={6}>
          {renderField(t("Company Name"), supplierData.company_name)}
          {renderField(t("Address"), supplierData.address)}
          {renderField(t("Postal Code"), supplierData.postal_code)}
          {renderField(t("City"), supplierData.city)}
          {renderField(t("Region"), supplierData.region)}
          {renderField(t("Department"), supplierData.department)}
        </Grid>

        <Grid item xs={12} sm={6}>
          {renderField(t("Country"), supplierData.country)}
          {renderField(t("Email"), supplierData.email)}
          {renderField(t("Office Phone"), supplierData.office_phone)}
          {renderField(t("Mobile Phone"), supplierData.mobile_phone)}
          {renderField(t("Website"), supplierData.website)}
        </Grid>

        {/* Contact Information */}
        <Grid item xs={12}>
          <Typography variant="h6" color="secondary">
            {t("Coordonnées")}
          </Typography>
          <Divider sx={{ mb: 2 }} />
        </Grid>

        <Grid item xs={12} sm={6}>
          {renderField(t("Last Name"), supplierData.contact_last_name)}
          {renderField(t("First Name"), supplierData.contact_first_name)}
          {renderField(t("Position"), supplierData.contact_position)}
        </Grid>

        <Grid item xs={12} sm={6}>
          {renderField(t("Contact Mobile"), supplierData.contact_mobile)}
          {renderField(t("Contact Email"), supplierData.contact_email)}
          {renderField(t("Contact Office Phone"), supplierData.contact_office_phone)}
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ShowSupplier;
