import useCategoryList from "features/Category/CategoryList/useCategoryList";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  SyntheticEvent,
} from "react";
import {
  IProductData,
  IProductDataEditingImage,
  IProductDataEditingImageError,
  IProductDataEditingError,
  IProductItemDataImage,
  IUseEditProps,
} from "./ProductEdit.types";
import { useTranslation } from "react-i18next";
import { isNumber } from "utils/helpers/global.helpers";
import { useNavigate } from "react-router-dom";
import {
  useGetProductQuery,
  useUpdateProductMutation,
} from "redux/api/product/product";
import { RouteIdEnum } from "config/enums";
import { useSnackBar } from "hooks/useSnackBar";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";

export default function useEditProductList({ id }: IUseEditProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const { categoryData } = useCategoryList();
  const [compteurImages, setCompteurImages] = useState(1);
  const {
    data: productItemData,
    isSuccess,
    refetch: refetchProductItem,
  } = useGetProductQuery(Number(id));
  const pointsOfSaleData =
    productItemData?.points_of_sale?.map((point_of_sale: any) => ({
      point_of_sale: point_of_sale.id,
      name: point_of_sale.name,
      quantity: point_of_sale.pivot?.quantity,
    })) || [];
  console.log("product1234567", productItemData);

  const [editingData, setEditingData] = useState<IProductData>({
    images: productItemData?.images,
    title: productItemData?.title,
    price: productItemData?.price,
    priceLolo: productItemData?.priceLolo,
    priceLoloTtc: productItemData?.priceLoloTtc,
    priceNonSubscriberTtc: productItemData?.priceNonSubscriberTtc,
    priceNonSubscriber: productItemData?.priceNonSubscriber,
    unite_format: productItemData?.unite_format,
    description: productItemData?.description,
    purchase_price: productItemData?.purchase_price,
    quantity: productItemData?.quantity,
    quantities: productItemData?.quantities,
    category_product_id: productItemData?.category_product_id,
    is_images_updated: productItemData?.is_images_updated,
    subCategory_product_id: productItemData?.Subcategory_product_id,
    tax_id: {
      id: productItemData?.taxes[0]?.id,
      name: productItemData?.taxes[0]?.name,
      percentage: productItemData?.taxes[0]?.percentage,
    },
    color_hex: productItemData?.color_hex,
    price_ttc: productItemData?.price_ttc,
    barcode: productItemData?.barcode,
    discount: productItemData?.discount,
    label: productItemData?.label,
    status: productItemData?.status,
    publication_date: productItemData?.publication_date,
    display: productItemData?.display,
    nutri_score: productItemData?.nutri_score,
    stock_alert: productItemData?.stock_alert,
    min_qty: productItemData?.min_qty,
    color: productItemData?.color,
    nutritional_values:
      productItemData?.nutritional_values.map(({ id, name, value }: any) => ({
        id,
        name,
        value,
      })) || [],
    provider_id: productItemData?.provider_id,
    marge: productItemData?.marge,
    categ_exped: {
      id: productItemData?.categ_exped,
      label: productItemData?.categ_exped_name,
    },
    keyWords: [productItemData?.keyWords],
    format: productItemData?.format,
    brand: productItemData?.brand_id,
    points_of_sale:
      productItemData?.points_of_sale?.map((point_of_sale: any) => ({
        point_of_sale: point_of_sale.id,
        name: point_of_sale.name,
        quantity: point_of_sale.pivot?.quantity,
      })) || [],
    garentie: productItemData?.garantie,
    dateLimiteCons: productItemData?.date_limite_de_consommation,
    DateDurabMin: productItemData?.date_de_durabilite_minimale,
    frais_stock: {
      id: productItemData?.frais_stock?.id,
      label: productItemData?.frais_stock?.name,
    },
  });

  const [editingError, setEditingError] = useState<IProductDataEditingError>({
    title: "",
    color: "",
    description: "",
    price: "",
    priceLolo: "",
    priceNonSubscriber: "",
    priceLoloTtc: "",
    priceNonSubscriberTtc: "",
    unite_format: "",
    quantity: "",
    category_product_id: "",
    images: "",
    purchase_price: "",
    subCategory_product_id: "",
    kdQuantity: "",
    tax_id: "",
    color_hex: "",
    price_ttc: "",
    barcode: "",
    discount: "",
    label: "",
    status: "",
    publication_date: "",
    display: "",
    nutri_score: "",
    stock_alert: "",
    min_qty: "",
    provider_id: "",
    nutritional_values: [],
    marge: "",
    categ_exped: "",
    keyWords: [],
    brand: "",
    frais_stock: ""
  });
  const { data: subcategories, isLoading } = useGetAllSubCategoriesQuery({
    id: editingData?.category_product_id,
    all: true,
  });

  const [subCategs, setSubCategs] = useState([]);
  const [addingImage, setAddingImage] = useState<IProductDataEditingImage[]>(
    []
  );
  useEffect(() => {
    if (productItemData && isSuccess) {
      setEditingData({
        title: productItemData?.title ? productItemData?.title : "",
        description: productItemData?.description
          ? productItemData?.description
          : "",
        price: productItemData?.price ? productItemData?.price : "",
        priceLolo: productItemData?.priceLolo ? productItemData?.priceLolo : "",
        priceNonSubscriber: productItemData?.priceNonSubscriber
          ? productItemData?.priceNonSubscriber
          : "",
        priceLoloTtc: productItemData?.priceLoloTtc
          ? productItemData?.priceLoloTtc
          : "",
        priceNonSubscriberTtc: productItemData?.priceNonSubscriberTtc
          ? productItemData?.priceNonSubscriberTtc
          : "",
        unite_format: productItemData?.unite_format
          ? productItemData?.unite_format
          : "",
        quantities: productItemData?.quantity ? productItemData?.quantity : {},
        category_product_id: productItemData?.category_product_id
          ? productItemData?.category_product_id
          : "",
        is_images_updated: 0,
        purchase_price: productItemData?.purchase_price
          ? productItemData?.purchase_price
          : "",
        subCategory_product_id: productItemData?.Subcategory_product_id
          ? productItemData?.Subcategory_product_id
          : "",
        tax_id: productItemData?.taxes
          ? {
            id: productItemData?.taxes[0]?.id,
            name: productItemData?.taxes[0]?.name,
            percentage: productItemData?.taxes[0]?.percentage,
          }
          : { id: 0, name: "Taxe", percentage: "" },
        color_hex: productItemData?.color_hex ? productItemData?.color_hex : "",
        price_ttc: `${parseFloat(productItemData.price ?? 0) +
          (parseFloat(productItemData.price ?? 0) *
            parseFloat(productItemData.tax_id?.percentage ?? "0")) /
          100
          }`,
        barcode: productItemData?.barcode ? productItemData?.barcode : "",
        discount: productItemData?.discount ? productItemData?.discount : "",
        label: productItemData?.label ? productItemData?.label : "",
        status: productItemData?.status ? productItemData?.status : "",
        publication_date: productItemData?.publication_date
          ? productItemData?.publication_date
          : "",
        display: productItemData?.display ? productItemData?.display : "",
        nutri_score: productItemData?.nutri_score
          ? productItemData?.nutri_score
          : "",
        stock_alert: productItemData?.stock_alert
          ? productItemData?.stock_alert
          : "",
        min_qty: productItemData?.min_qty ? productItemData?.min_qty : "",
        color: productItemData?.color ? productItemData?.color : "",
        nutritional_values:
          productItemData?.nutritional_values.map(
            ({ id, name, value }: any) => ({ id, name, value })
          ) || [],
        provider_id: productItemData?.provider_id
          ? productItemData?.provider_id
          : "",
        marge: productItemData?.marge ? productItemData?.marge : "",
        categ_exped: productItemData?.categ_exped
          ? {
            id: productItemData?.categ_exped,
            label: productItemData?.categ_exped_name,
          }
          : { id: 0, label: "" },
        frais_stock: productItemData?.frais_stock
          ? {
            id: productItemData?.frais_stock.id,
            label: productItemData?.frais_stock.name,
          }
          : { id: 0, label: "" },
        keyWords: productItemData?.keyWords ? productItemData?.keyWords : "",
        images: [],
        format: productItemData?.format ? productItemData?.format : "",
        brand: productItemData?.brand_id ? productItemData?.brand_id : "",
        points_of_sale:
          productItemData?.points_of_sale?.map((point_of_sale: any) => ({
            point_of_sale: point_of_sale.id,
            name: point_of_sale.name,
            quantity: point_of_sale.pivot?.quantity,
          })) || [],
        garentie: productItemData?.garantie,
        dateLimiteCons: productItemData?.date_limite_de_consommation,
        DateDurabMin: productItemData?.date_de_durabilite_minimale,
        quantity: productItemData?.quantity ? productItemData?.quantity : "",
      });
      productItemData.image_products?.map((item: IProductItemDataImage) => {
        setAddingImage((prevState: any) => [
          ...prevState,
          {
            id: item.id,
            color_name: item.color_name,
            color_hex: item.color_hex,
            is_main_image: item.is_main_image,
            file: item.image,
          },
        ]);
        if (typeof item.id == "number" && item.id > compteurImages) {
          setCompteurImages(item.id + 1);
        }
      });
    }
  }, [isSuccess, productItemData]);
  const handleDeleteImage = (itemData: any) => {
    console.log("itemData", itemData);
    setAddingImage((prevState: any) =>
      prevState.filter((item: any) => item.id !== itemData.id)
    );
  };
  const handleDatePickerChange = (date: string, field: string) => {
    // Check if the date is valid
    if (date && !isNaN(new Date(date).getTime())) {
      const formattedDate = new Date(date).toISOString().split("T")[0];
      setEditingData({ ...editingData, [field]: formattedDate });
    } else {
      // If date is invalid or empty, set the field to null or handle accordingly
      setEditingData({ ...editingData, [field]: null });
    }
  };

  const onChangeSelectCategory = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, category_product_id: e });
      /*       validateWhenChange()
       */ setSubCategs(subcategories);
      setEditingError({ ...editingError, category_product_id: "" });
    },
    [editingData, editingError]
  );
  const onChangeSelectShow = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, display: e });
      setEditingError({ ...editingError, display: "" });
    },
    [editingData, editingError]
  );
  const onChangeSelectProvider = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, provider_id: e });
      setEditingError({ ...editingError, provider_id: "" });
    },
    [editingData, editingError]
  );
  const onChangeSelectBrand = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, brand: e });
      setEditingError({ ...editingError, brand: "" });
    },
    [editingData, editingError]
  );
  const onChangeSelectCategExped = (
    event: SyntheticEvent<Element, Event>,
    newValue: { id: string; label: string } | null
  ) => {
    console.log("newValue", newValue);
    setEditingData({ ...editingData, categ_exped: newValue });
    setEditingError({ ...editingError, categ_exped: "" });
  };
  const onChangeSelectFrais = (
    event: SyntheticEvent<Element, Event>,
    newValue: { id: string; label: string } | null
  ) => {
    console.log("newValue", newValue);
    setEditingData({ ...editingData, frais_stock: newValue });
    setEditingError({ ...editingError, frais_stock: "" });
  };

  const onChangeSelectSubCategory = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, subCategory_product_id: e });
      setEditingError({ ...editingError, subCategory_product_id: "" });
    },
    [editingData, editingError]
  );
  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    pointOfSaleId: number
  ) => {
    const newQuantity = e.target.value;
    const updatedPointsOfSale = editingData.points_of_sale.map((pos: any) => {
      if (pos.point_of_sale === pointOfSaleId) {
        return {
          ...pos,
          quantity: newQuantity,
        };
      }
      return pos;
    });

    setEditingData({
      ...editingData,
      points_of_sale: updatedPointsOfSale,
    });
  };

  const onChangeSelectStatus = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, status: e });
      setEditingError({ ...editingError, status: "" });
    },
    [editingData, editingError]
  );
  const [isImagesUpdated, setIsImagesUpdated] = useState<number>(0);
  const checkImagesUpdated = useCallback(() => {
    if (!isImagesUpdated) {
      setIsImagesUpdated(1);
      setAddingImage([]);
    }
  }, [isImagesUpdated]);
  const [addingOneImage, setAddingOneImage] = useState<any>({
    id: 0,
    color_name: "",
    color_hex: "",
    is_main_image: 0,
    file: null,
  });
  const onChangeKeyWords = (e: any, keyWords: any) => {
    setEditingData({ ...editingData, keyWords: keyWords });
    setEditingError({ ...editingError, keyWords: [] });
  };
  const onChangeSelectTax = (
    e: any,
    newValue: { id: number; name: string; percentage: string }
  ) => {
    setEditingData({ ...editingData, tax_id: newValue });
    setEditingError({ ...editingError, tax_id: "" });
  };
  const onChangeSelectNutriscore = useCallback(
    (field: string) => (e: string) => {
      setEditingData({ ...editingData, nutri_score: e });
      setEditingError({ ...editingError, nutri_score: "" });
    },
    [editingData, editingError]
  );
  const [isMainImageSelected, setIsMainImageSelected] =
    useState<boolean>(false);
  const [addingOneImageError, setAddingOneImageError] =
    useState<IProductDataEditingImageError>({
      color_name: "",
      color_hex: "",
      file: "",
    });
  const onChange = useCallback(
    (posId: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingError((prevErrors) => ({
          ...prevErrors,
          quantity: "",
        }));
        setEditingData((prevData) => ({
          ...prevData,
          quantities: {
            ...prevData.quantities,
            [posId]: e.target.value,
          },
        }));
      },
    []
  );
  const onChangeSelectFormatUnit = useCallback(
    (field: string) => (e: string) => {
      console.log(e);
      setEditingData({ ...editingData, unite_format: e });
      setEditingError({ ...editingError, unite_format: "" });
    },
    [editingData, editingError]
  );
  const onChangeOneImage = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingOneImage({
          ...addingOneImage,
          [field]: e.target.value,
        });
        setAddingOneImageError({
          color_name: "",
          color_hex: "",
          file: "",
        });
        if (field === "is_main_image") {
          setAddingOneImage({
            ...addingOneImage,
            is_main_image: addingOneImage.is_main_image ? 0 : 1,
          });
        }
        if (field === "file") {
          const files = (e.target as HTMLInputElement).files;
          if (files)
            setAddingOneImage({
              ...addingOneImage,
              file: files[0],
            });
        }
      },
    [addingOneImage]
  );
  const onSaveImage = useCallback(() => {
    setIsImagesUpdated(1);
    const newAddingOneImage = {
      ...addingOneImage,
      id: compteurImages + 1,
    };

    setAddingImage((prevState) => [...prevState, newAddingOneImage]);

    if (newAddingOneImage.is_main_image) {
      setIsMainImageSelected(true);
    }

    setAddingOneImage({
      id: compteurImages + 1,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });

    // Increment compteurImages state
    setCompteurImages(compteurImages + 1);

    // Reset addingOneImageError state
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });
  }, [addingOneImage, checkImagesUpdated]);
  //addling dialog
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);
  const closeAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingOneImage({
      id: compteurImages,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });
  }, [setOpenAddingDialog]);
  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    if (isImageValidate()) {
      onSaveImage();
      closeAddingDialogContainer();
    }
  };


  const isImageValidate = () => {
    let ok = true;
    let addingImg = {
      color_name: "",
      color_hex: "",
      file: "",
    };
    // if (addingOneImage.color_hex === "") {
    //   ok = false;
    //   addingImg.color_hex = t("product.color_hex_required");
    // }
    // if (addingOneImage.color_name === "") {
    //   ok = false;
    //   addingImg.color_name = t("product.color_name_required");
    // }
    if (addingOneImage.file === null) {
      ok = false;
      addingImg.file = t("product.file_required");
    }
    if (!ok) {
      setAddingOneImageError(addingImg);
    }
    return ok;
  };
  const isRequired = (value: any) =>
    value !== "" && value !== undefined && value !== null;
  const isPositiveNumber = (value: any): boolean => {
    // Vérifiez si la valeur est définie
    if (value === undefined || value === null) {
      return false; // Ou gérer l'erreur selon vos besoins
    }
    return isNumber(value) && Number(value) > 0;
  };
  const isValidate = () => {
    let ok = true;
    const addingProduct: any = {};
    const validationRules: {
      [key: string]: { rule: Function; message: string }[];
    } = {
      title: [{ rule: isRequired, message: t("product.title_required") }],
      description: [
        { rule: isRequired, message: t("product.description_required") },
      ],
      price: [
        { rule: isRequired, message: t("product.price_required") },
        { rule: isPositiveNumber, message: t("product.price_required") },
      ],
      category_product_id: [
        {
          rule: isRequired,
          message: t("product.category_product_id_required"),
        },
      ],
      subCategory_product_id: [
        {
          rule: isRequired,
          message: t("product.subCategory_product_id_required"),
        },
      ],
      purchase_price: [
        { rule: isRequired, message: t("product.price_HT_required") },
      ],
      tax_id: [{ rule: isRequired, message: "Taxe obligatoire" }],
      brand: [{ rule: isRequired, message: "Marque obligatoire" }],
      points_of_sale: [
        { rule: isQuantityValid, message: t("product.quantity_required") },
      ],
      barcode: [{ rule: isRequired, message: t("product.bar_code_required") }],
      publication_date: [
        { rule: isRequired, message: t("product.publication_date_required") },
      ],
      status: [{ rule: isRequired, message: t("product.status_required") }],
      display: [{ rule: hasShow, message: t("product.show_required") }],
      categ_exped: [
        { rule: isRequired, message: "Catégorie expédition obligatoire" },
      ],
      provider_id: [
        { rule: isRequired, message: t("product.provider_id_required") },
      ],
      images: [{ rule: hasImages, message: "image is required" }],

      stock_alert: [
        {
          rule: (value: any) => isRequired(value) && isPositiveNumber(value),
          message: "Le seuil de stock doit être positif",
        },
        { rule: isRequired, message: "Seuil de stock obligatoire" },
      ],
    };
    if (editingData.status == "1") {
      delete validationRules.description;
      delete validationRules.price;
      delete validationRules.category_product_id;
      delete validationRules.subCategory_product_id;
      delete validationRules.purchase_price;
      delete validationRules.tax_id;
      delete validationRules.brand;
      delete validationRules.points_of_sale;
      delete validationRules.barcode;
      delete validationRules.publication_date;
      delete validationRules.display;
      delete validationRules.categ_exped;
      delete validationRules.provider_id;
      delete validationRules.images;
      delete validationRules.stock_alert;
    }
    if (editingData.status == "2") {
      delete validationRules.publication_date;
    }
    if (editingData.status == "3" && !editingData.publication_date) {
      ok = false;
      addingProduct.publication_date = t("product.publication_date_required");
    }
    console.log("validationRules", validationRules);
    Object.keys(validationRules).forEach((field) => {
      console.log("field", field);
      validationRules[field].forEach(({ rule, message }) => {
        if (!rule(editingData[field])) {
          ok = false;
          addingProduct[field] = message;
        } else {
          addingProduct[field] = "";
        }
      });
    });
    console.log("addingError", addingProduct);
    console.log("editingData", editingData);
    if (!ok) {
      setEditingError(addingProduct);
    }

    return ok;
  };

  // Validation helpers

  const hasShow = (value: any) => Array.isArray(value) && value.length > 0;
  const hasImages = (value: any) => Array.isArray(value) && value.length > 0;

  const isQuantityValid = (quantity: any) => {
    console.log("quantity", quantity);

    if (
      !quantity ||
      Object.keys(quantity).length === 0 ||
      Object.keys(quantity).length < editingData.points_of_sale.length
    ) {
      return false;
    }

    for (const value of Object.values(quantity)) {
      console.log("value", value);

      // Assertion de type
      const item = value as { quantity: number | string }; // Ajustez le type ici selon vos besoins

      if (!item || parseInt(String(item.quantity), 10) < 0) {
        return false;
      }
    }

    return true;
  };

  const [updateProduct, { data, error, reset }] = useUpdateProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_editing"));
    reset();
    refetchProductItem();
    navigate("/" + RouteIdEnum.Products);
  }
  const addProductForm = () => {
    editingData.images = [];
    addingImage.map((item, index) => {
      editingData.images.push({
        file: item.file,
        is_main_image: item.is_main_image,
        color_hex: item.color_hex,
        id: item.id,
        color_name: item.color_name,
      });
    });
    if (isValidate()) {
      console.log("terfer", editingData);

      const formData = new FormData();

      formData.append("title", editingData.title);
      formData.append("description", editingData.description);
      formData.append("price", editingData.price);
      formData.append("priceLolo", editingData.priceLolo);
      formData.append("priceLoloTtc", editingData.priceLoloTtc);
      formData.append("unite_format", editingData.unite_format?.id);
      formData.append("priceNonSubscriber", editingData.priceNonSubscriber);
      formData.append(
        "priceNonSubscriberTtc",
        editingData.priceNonSubscriberTtc
      );
      editingData.points_of_sale.forEach((pos: any, index: number) => {
        formData.append(
          `quantities[${index}][point_of_sales_id]`,
          pos.point_of_sale || ""
        );
        formData.append(`quantities[${index}][quantity]`, pos.quantity || "0");
      });
      formData.append("category_product_id", editingData.category_product_id);
      formData.append("purchase_price", editingData.purchase_price);
      editingData.subCategory_product_id
        ? formData.append(
          "Subcategory_product_id",
          editingData.subCategory_product_id
        )
        : formData.append("Subcategory_product_id", "");
      editingData.tax_id?.id
        ? formData.append("tax_id", `${editingData.tax_id?.id}`)
        : formData.append("tax_id", "");
      formData.append("discount", editingData.discount);
      formData.append(
        "price_ttc",
        `${parseFloat(editingData.price ?? 0) +
        (parseFloat(editingData.price ?? 0) *
          parseFloat(editingData.tax_id?.percentage ?? "0")) /
        100
        }`
      );
      formData.append("barcode", editingData.barcode);
      formData.append("is_images_updated", `${isImagesUpdated}`);
      formData.append("label", editingData.label);
      formData.append("status", editingData.status);
      formData.append("display", JSON.stringify(editingData.display));
      formData.append("nutri_score", editingData.nutri_score);
      formData.append("stock_alert", editingData.stock_alert);
      formData.append("min_qty", editingData.min_qty);
      formData.append("marge", editingData.marge);
      formData.append("format", editingData.format);
      formData.append("brand", editingData.brand);
      formData.append("garentie", editingData?.garentie ?? "");
      formData.append("publication_date", editingData?.publication_date ?? "");
      formData.append("dateLimiteCons", editingData.dateLimiteCons ?? "");
      formData.append("DateDurabMin", editingData.DateDurabMin ?? "");
      formData.append("quantity", editingData.quantity);
      editingData.categ_exped
        ? formData.append("categ_exped", editingData.categ_exped.id)
        : formData.append("categ_exped", "");

      editingData.frais_stock
        ? formData.append("frais_stock", editingData.frais_stock.id)
        : formData.append("frais_stock", "");

      editingData.nutritional_values.forEach(
        (nutritional_value: any, index: number) => {
          const nutritionalname = nutritional_value.name;
          const nutritionalvalue = nutritional_value.value;

          formData.append(
            "nutritional_values[" + index + "][name]",
            nutritionalname
          );

          formData.append(
            "nutritional_values[" + index + "][value]",
            nutritionalvalue
          );
        }
      );
      editingData.keyWords.forEach((keyWord: any, index: number) => {
        formData.append("keyWords[" + index + "]", keyWord);
      });
      formData.append("provider", editingData.provider_id);
      // Handle file uploads for the images field
      editingData.images.forEach((image: any, index: number) => {
        const file = image.file;
        const isMainImage = image.is_main_image;
        const colorHex = image.color_hex;
        const colorName = image.color_name;

        formData.append("images[" + index + "][file]", file);
        formData.append("images[" + index + "][is_main_image]", isMainImage);
        formData.append("images[" + index + "][color_hex]", colorHex);
        formData.append("images[" + index + "][color_name]", colorName);
      });
      console.log(formData);

      updateProduct({ id: id, formData: formData });
    }
  };

  return {
    categoryData,
    editingData,
    editingError,
    onChange,
    onChangeSelectCategory,
    openAddingDialogContainer,
    addingImage,
    addProductForm,
    openAddingDialog,
    closeAddingDialogContainer,
    onSuccessAddingDialog,
    addingOneImage,
    onChangeOneImage,
    addingOneImageError,
    isMainImageSelected,
    productItemData,
    setEditingData,
    onChangeSelectSubCategory,
    onChangeSelectTax,
    onChangeSelectProvider,
    onChangeSelectShow,
    onChangeSelectCategExped,
    onChangeSelectFrais,
    onChangeKeyWords,
    onChangeSelectStatus,
    onChangeSelectNutriscore,
    setEditingError,
    subcategories,
    handleDeleteImage,
    onChangeSelectBrand,
    isSuccess,
    handleQuantityChange,
    handleDatePickerChange,
    onChangeSelectFormatUnit,
  };
}
