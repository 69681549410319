import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/layouts/DashboardLayout/DashboardLayout";
import StockPlusTable from "./StockPlusTable";


const StockPlus = () => {
    return (
        <DashboardLayout
            title={"Stock Plus"}
            selectedPath={RouteIdEnum.stockPlus}
        >
            <StockPlusTable />
        </DashboardLayout>
    );
};

export default StockPlus;
