export const common = {
  not_found_page_title: "404 Page not found",
  no_data: "Aucun élément trouvé",
  loading: "Chargement...",
  delete_message: "Etes-vous sûr de vouloir supprimer",
  note:"Tous les produits et sous-catégories de cette catégorie seront supprimés",
  note2:"Tous les produits de cette sous catégorie seront supprimés",
  confirm_button: "Confirmer",
  cancel_button: "Annuler",
  save_button: "Enregistrer",
  add: "Ajouter",
  delete: "Supprimer",
  edit: "Editer",
  status: "Status",
  search: "Rechercher",
  actions: "Actions",
  created_at: "Date de création",
  unauthorized: "Non autorisé",
  back_home: "Retour à la page d'accueil",
  show:"Afficher",
  subcategory:"Afficher les sous catégories",
  code:"Code",
  import_csv:"Importer CSV",
  publish:"Publier",
  draft:"Mettre en brouillon",
  draft_message:"Voulez vous mettre en brouillon",
  publish_message:"Voulez vous publier"
};
