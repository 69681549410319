import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const clientApi = createApi({
  reducerPath: "ClientApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["client"],
  endpoints: (builder) => ({
    getAllClient: builder.query({
      query: (params: { paginator?: Paginator,all?:boolean }) => {
        return injectParamsPaginator(ENDPOINTS.CLIENT, params.paginator,params.all);
      },
      providesTags: ["client"],
    }),
    updateClientStatus: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.CLIENT + ENDPOINTS.UPDATE_STATUS}/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["client"],
    }),
    getClientById: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.CLIENT}/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllClientQuery, useUpdateClientStatusMutation ,useGetClientByIdQuery} =
  clientApi;
