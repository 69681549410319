import { IPointOfSale } from "features/Product/ProductList/Product.types";
import { Paginator } from "types/interfaces";

// Inject a paginator in a request params
export const injectPaginator = (url: string, paginator?: Paginator, all?: boolean, isAdmin?: boolean, category?: any, Subcategory?: any, Stock?: any, Garantie?: any, DLC?: boolean, Status?: any, POS?: IPointOfSale | undefined) => {
  if (paginator) {
    const { page, search, perPage, orderBy, orderDir } = paginator;
    let path = `${url}?page=${page}&perPage=${perPage}`;
    if (orderBy) {
      path = `${path}&orderBy=${orderBy}`;
    }
    if (orderDir) {
      path = `${path}&orderDirection=${orderDir}`;
    }
    if (search) {
      path = `${path}&search=${search}`;
    }
 
    if (all) {
      path = `${path}&all=${all}`;
    }
    if (isAdmin) {
      path = `${path}&isAdmin=${isAdmin}`;
    }
    if (category) {
      path = `${path}&categ=${category}`;
    }
     if (Subcategory) {
      path = `${path}&Subcategory=${Subcategory}`;
    }
    if (Stock) {
      path = `${path}&Stock=${Stock}`;
    }
    if (Garantie) {
      path = `${path}&Garantie=${Garantie}`;
    }
    if (DLC) {
      path = `${path}&DLC=${DLC}`;
    }
    if (Status) {
      path = `${path}&Status=${Status}`;
    }
    if (POS) {
      path = `${path}&pos=${POS}`;
    }
    return path;
  }else if(all){
    let path = `${url}?all=${all}`;
    return path;
  } else {
    return url;
  }
};

export const arrayToParams = (key: string, array: Array<number | string>) => {
  let string = "";
  array.forEach((value) => {
    string += `${key}[]=${value}&`;
  });
  return string;
};
