import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./AddPromotionCode.style";
import AutoComplete from "components/common/AutoComplete/AutoComplete";

import dayjs, { Dayjs } from "dayjs";

import SelectInputShow from "components/common/SelectInput/SelectInputShow";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { useAddPromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import {
  IPromotionCodesAddingData,
  IPromotionCodesAddingDataError,
  IPromotionCodesDialog,
} from "./AddPromotionCode.types";
import { useSnackBar } from "hooks/useSnackBar";
import moment from "moment";
import TextInput from "components/common/TextInput/TextInput";
import SelectInput from "components/common/SelectInput/SelectInput";
import { CheckBox } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { AutoInputsContainerStyle } from "features/Product/ProductAdd/Product.style";
import useCategoryList from "features/Category/CategoryList/useCategoryList";
import useSubCategoryList from "features/SubCategory/SubCategoryList/useSubCategoryList";
import useProductList from "features/Product/ProductList/useProductList";
import useClientList from "features/Client/ClientList/useClientList";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import {
  useGetAllCategoryQuery,
  useGetAllSubCategoriesQuery,
} from "redux/api/category/category";
import { GlobalVariables } from "config/constant/global.variables";
import { getNonSubscribers, getSubscribers } from "UpdatedFeatures/api";
import { useQuery } from "@tanstack/react-query";

function AddPromotionCode({
  openAddingDialog,
  setOpenAddingDialog,
}: IPromotionCodesDialog) {
  const { t } = useTranslation();
  // const { categoryData } = useCategoryList();
  const { subCategoryData } = useSubCategoryList();
  const { data: subcategories } = useGetAllSubCategoriesQuery({
    all: true,
  });
  const { data: categories } = useGetAllCategoryQuery({
    all: true,
  });
  const { productData } = useProductList(true);

  const { data: subscribers } = useQuery({
    queryKey: ["subscribers"],
    queryFn: () => getSubscribers(),
  });
  const { data: NonSubscribers } = useQuery({
    queryKey: ["NonSubscribers"],
    queryFn: () => getNonSubscribers(),
  });
  console.log("ssssss",subscribers)

  const [isValid, setIsValid] = useState();
  // console.log(subCategorydata)
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [addingCode, { data, error, isLoading, reset }] =
    useAddPromotionCodeMutation();

  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingData({
      code: "",
      percentage: 0,
      email: "",
      link: "",
      categories: [],
      subCategories: [],
      type: "",
      isLimitedusers: "unlimited",
      isLinkedUsers: false,
      isUserExist: "",
      client: "",
      percentage_value: "",
      eur_value: "",
      isUserNotExist: false,
      linkcodesubscription: false,
      subscriptions: "",
      isLinkedPromoSubscription: false,
      isUnlimitedUsers: false,
      isDiscountMin: false,
      discount: "",
      nbUser: "",
      products: [],
      start_date: new Date(),
      end_date: new Date(new Date().setMinutes(new Date().getMinutes() + 1)),
      affectation: "",
    });
    setAddingError({
      code: "",
      percentage: 0,
      email: "",
      link: "",
      categories: "",
      subCategories: "",
      type: "",
      isLimitedusers: "unlimited",
      isLinkedUsers: false,
      isUserExist: "",
      client: "",
      percentage_value: "",
      eur_value: "",
      isUserNotExist: false,
      linkcodesubscription: false,
      subscriptions: "",
      isLinkedPromoSubscription: false,
      isUnlimitedUsers: false,
      isDiscountMin: false,
      discount: "",
      nbUser: "",
      product: "",
      start_date: "",
      end_date: "",
      affectation: "",
    });
  }, [setOpenAddingDialog]);
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    closeAddingDialog();
    displaySuccessSnackBar(t("promotion_codes.successfully_adding"));
    reset();
  }

  const [addingData, setAddingData] = useState<IPromotionCodesAddingData>({
    code: "",
    percentage: 0,
    client: "",
    link: "",
    categories: [],
    subCategories: [],
    type: "",
    isLimitedusers: "",
    isLinkedUsers: false,
    isUserExist: "",
    percentage_value: "",
    eur_value: "",
    isUserNotExist: false,
    linkcodesubscription: false,
    subscriptions: "",
    isLinkedPromoSubscription: false,
    isDiscountMin: false,
    isUnlimitedUsers: false,
    discount: "",
    email: "",
    products: [],
    start_date: new Date(),
    nbUser: "",
    end_date: new Date(new Date().setMinutes(new Date().getMinutes() + 1)),
    affectation: "",
  });
  const [addingError, setAddingError] =
    useState<IPromotionCodesAddingDataError>({
      code: "",
      percentage: 0,
      email: "",
      client: "",
      link: "",
      categories: "",
      subCategories: "",
      type: "",
      isLimitedusers: "",
      isLinkedUsers: false,
      isLinkedPromoSubscription: false,
      nbUser: "",
      isUserExist: "",
      percentage_value: "",
      eur_value: "",
      isUserNotExist: false,
      linkcodesubscription: false,
      isUnlimitedUsers: false,
      subscriptions: "",
      isDiscountMin: false,
      discount: "",
      product: "",
      start_date: "",
      end_date: "",
      affectation: "",
    });
  const validate = () => {
    let ok = true;
    let addingPromotionError: IPromotionCodesAddingDataError = {
      code: "",
      percentage: 0,
      email: "",
      client: "",
      link: "",
      categories: "",
      subCategories: "",
      type: "",
      isLimitedusers: "",
      isLinkedUsers: false,
      isLinkedPromoSubscription: false,
      nbUser: "",
      isUserExist: "",
      percentage_value: "",
      eur_value: "",
      isUserNotExist: false,
      linkcodesubscription: false,
      isUnlimitedUsers: false,
      subscriptions: "",
      isDiscountMin: false,
      discount: "",
      product: "",
      start_date: "",
      end_date: "",
      affectation: "",
    };
    if (addingData.code == "") {
      ok = false;
      addingPromotionError.code = "Code obligatoire";
    } else {
      addingPromotionError.code = "";
    }
    if (addingData.link == "") {
      addingPromotionError.link = "Liaison obligatoire";
    } else {
      addingPromotionError.link = "";
    }
    if (addingData.link == "3" && addingData.products.length == 0) {
      ok = false;
      addingPromotionError.product = "Veuillez selectionnez les produits";
    } else {
      addingPromotionError.product = "";
    }
    if (addingData.link == "1" && addingData.categories?.length == 0) {
      ok = false;
      addingPromotionError.categories = "Veuillez selectionnez les catégories";
    } else {
      addingPromotionError.categories = "";
    }
    if (addingData.link == "2" && addingData.subCategories?.length == 0) {
      ok = false;
      addingPromotionError.subCategories =
        "Veuillez selectionnez les sous catégories";
    } else {
      addingPromotionError.subCategories = "";
    }

    if (addingData.type == "") {
      addingPromotionError.type = "Type obligatoire";
    } else {
      addingPromotionError.type = "";
    }
    if (addingData.start_date == null) {
      addingPromotionError.start_date = "Date début obligatoire";
    } else {
      addingPromotionError.start_date = "";
    }
    if (addingData.end_date == null) {
      addingPromotionError.end_date = "Date fin obligatoire";
    } else {
      addingPromotionError.end_date = "";
    }
    if (
      Number(addingData.percentage_value) < 0 ||
      Number(addingData.percentage_value) > 100
    ) {
      ok = false;
      addingPromotionError.percentage_value = "Pourcentage entre 0 et 100";
    } else addingPromotionError.percentage_value = "";

    console.log("addingPromotionError", addingPromotionError);
    setAddingError(addingPromotionError);
    return ok;
  };
  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    let link;
    addingData.link == "1"
      ? (link = "categories")
      : addingData.link == "2"
      ? (link = "subcategories")
      : (link = "products");
    let type;
    addingData.type == "1"
      ? (type = "product_percent")
      : addingData.type == "3"
      ? (type = "product_value")
      : addingData.type == "2"
      ? (type = "free_shipping")
      : (type = "");

    const data: IPromotionCodesAddingData = { ...addingData };
    data.link = link;
    data.type = type;

    let categories_ids = addingData.categories.map((categ) => categ.id);
    let subCategories_ids = addingData.subCategories.map((subcat) => subcat.id);
    let products_ids = addingData.products.map((prod) => prod.id);

    if (validate())
      addingCode({
        ...data,
        products: products_ids,
        categories: categories_ids,
        subCategories: subCategories_ids,
        start_date: Math.floor(addingData.start_date.getTime() / 1000),
        end_date: Math.floor(addingData.end_date.getTime() / 1000),
      });
  };
  useEffect(() => {
    setAddingData({
      ...addingData,
      start_date: new Date(),
      end_date: new Date(new Date().setMinutes(new Date().getMinutes() + 1)),
    });
  }, []);
  useEffect(() => {
    console.log("addingData", addingData);
  }, [addingData]);
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        validate();

        setAddingData({ ...addingData, [field]: e.target.value });

        if (field === "start_date" || field === "end_date") {
          setAddingData({
            ...addingData,
            [field]: moment(e.target.value).format("YYYY/MM/DD"),
          });
        }
        if (field == "percentage_value") {
          if (Number(e.target.value) > 0 && Number(e.target.value) <= 100) {
            setAddingData({ ...addingData, percentage_value: e.target.value });
          } else {
            setAddingData({ ...addingData, percentage_value: "" });
          }
        }
      },
    [addingData, addingError]
  );
  const handleInputChange = (event: any, newValue: any, type: string) => {
    switch (type) {
      case "products": {
        setAddingData({
          ...addingData,
          products: [...newValue], // Adding new element to the array
        });
        setAddingError((prevState) => ({
          ...prevState,
          product: "", // Adding new element to the array
        }));
        break;
      }
      case "categories": {
        setAddingData({
          ...addingData,
          categories: [...newValue], // Adding new element to the array
        });
        setAddingError((prevState) => ({
          ...prevState,
          categories: "", // Adding new element to the array
        }));
        break;
      }
      case "subcats": {
        setAddingData({
          ...addingData,
          subCategories: [...newValue], // Adding new element to the array
        });
        setAddingError((prevState) => ({
          ...prevState,
          subCategories: "", // Adding new element to the array
        }));
        break;
      }
    }
  };
  const onChangeSelectLink = (e: string) => {
    setAddingData({ ...addingData, link: e.toString() });
    console.log("addingData", addingData);
    setAddingError({ ...addingError, link: "" });
  };
  const onChangeSelectAffectation = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, [field]: e });
      setAddingError({ ...addingError, [field]: "" });
    },
    [addingData, addingError]
  );

  const onChangePercentageValue = (e: any) => {
    setAddingData({ ...addingData, percentage_value: e.target.value });
  };
  const onChangeEurValue = (e: any) => {
    setAddingData({ ...addingData, eur_value: e.target.value });
  };

  const onChangeSelectType = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, type: e.toString() });
      setAddingError({ ...addingError, type: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectSubcscription = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, subscriptions: e });
      setAddingError({ ...addingError, subscriptions: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectClient = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, client: e });
      setAddingError({ ...addingError, client: "" });
    },
    [addingData, addingError]
  );
  const handleDelete = (item: string) => {
    setAddingData((prevItems: any) => {
      if (prevItems.products.length > 0) {
        const updatedKeyWords = prevItems.producuts.filter(
          (itemKey: string, index: number) => itemKey !== item
        );
        return {
          ...prevItems,
          products: updatedKeyWords,
        };
      }
    });
  };

  return (
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("promotion_codes.add")}
    >
      <form onSubmit={onSuccessAddingDialog}>
        <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
          <SelectInput
            label={"affectation"}
            error={addingError.affectation}
            value={addingData.affectation}
            onChange={onChangeSelectAffectation("affectation")}
            data={[
              { id: 0, title: "Lolo Drive" },
              { id: 1, title: "KD Marche" },
              { id: 2, title: "Lolo Drive & KD Marche" },
            ].map((e: any) => ({
              id: e.id,
              name: e.title,
            }))}
          />
        </AutoInputsContainerStyle>

        <TextInput
          value={addingData.code}
          label={t("promotion_codes.code")}
          onChange={onChange("code")}
          error={t(addingError.code)}
        />

        <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
          <SelectInput
            label={"liaison"}
            error={addingError.link}
            value={addingData.link}
            onChange={onChangeSelectLink}
            data={[
              { id: 1, name: "Categories" },
              { id: 2, name: "Sous Catégories" },
              { id: 3, name: "Produit" },
            ]}
          />
        </AutoInputsContainerStyle>
        {addingData.link == "1" && (
          <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
            {categories && (
              <AutoComplete
                label=""
                multiple={true}
                data={categories?.data?.map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
                getOptionLabel={(option: any) => {
                  return option ? option.name : "Catgéorie";
                }}
                value={
                  addingData.categories
                    ? addingData.categories
                    : [{ id: 0, name: "Tous" }]
                }
                onChange={(event: any, newValue: any) => {
                  console.log("newValue", newValue);

                  handleInputChange(event, newValue, "categories");
                }}
                renderTags={(value: any, getTagProps: any) => {
                  console.log("value", value);
                  return value.map((option: any, index: number) => {
                    console.log("option", option);
                    return (
                      <Chip {...getTagProps({ index })} label={option.name} />
                    );
                  });
                }}
                noOptionsText={
                  <a style={{ cursor: "pointer", textDecoration: "underline" }}>
                    Aucun produit trouvé
                  </a>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={addingError.subCategories !== ""}
                    helperText={addingError.subCategories}
                    label="Catégories"
                  />
                )}
              />
            )}
          </AutoInputsContainerStyle>
        )}
        {addingData.link == "2" && (
          <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
            {subCategoryData && (
              <AutoComplete
                label=""
                multiple={true}
                data={subCategoryData?.data?.map((e: any) => ({
                  id: e.id,
                  name: e.title,
                }))}
                getOptionLabel={(option: any) => {
                  return option ? option.name : "Produit";
                }}
                value={
                  addingData.subCategories
                    ? addingData.subCategories
                    : [{ id: 0, name: "Tous" }]
                }
                onChange={(event: any, newValue: any) => {
                  console.log("newValue", newValue);

                  handleInputChange(event, newValue, "subcats");
                }}
                renderTags={(value: any, getTagProps: any) => {
                  console.log("value", value);
                  return value.map((option: any, index: number) => {
                    console.log("option", option);
                    return (
                      <Chip {...getTagProps({ index })} label={option.name} />
                    );
                  });
                }}
                noOptionsText={
                  <a style={{ cursor: "pointer", textDecoration: "underline" }}>
                    Aucun produit trouvé
                  </a>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={addingError.subCategories !== ""}
                    helperText={addingError.subCategories}
                    label="Sous catégories"
                  />
                )}
              />
            )}
          </AutoInputsContainerStyle>
        )}
        {addingData.link == "3" && (
          <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
            {productData && (
              <AutoComplete
                label=""
                multiple={true}
                data={productData?.data
                  ?.filter((product: any) => product.status === 2)
                  ?.map((e: any) => ({
                    id: e.id,
                    name: e.title,
                  }))}
                getOptionLabel={(option: any) => {
                  return option ? option.name : "Produit";
                }}
                value={
                  addingData.products
                    ? addingData.products
                    : [{ id: 0, name: "Tous" }]
                }
                onChange={(event: any, newValue: any) => {
                  console.log("newValue", newValue);

                  handleInputChange(event, newValue, "products");
                }}
                renderTags={(value: any, getTagProps: any) => {
                  console.log("value", value);
                  return value.map((option: any, index: number) => {
                    console.log("option", option);
                    return (
                      <Chip {...getTagProps({ index })} label={option.name} />
                    );
                  });
                }}
                noOptionsText={
                  <a style={{ cursor: "pointer", textDecoration: "underline" }}>
                    Aucun produit trouvé
                  </a>
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={addingError.product !== ""}
                    helperText={addingError.product}
                    label="Produit"
                  />
                )}
              />
            )}
          </AutoInputsContainerStyle>
        )}

        <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
          <SelectInput
            label={"Type code promotion"}
            error={addingError.type}
            value={addingData.type}
            onChange={onChangeSelectType("type")}
            data={[
              { id: 1, name: "Remise en % sur Produit" },
              { id: 2, name: "Livraison gratuite" },
              { id: 3, name: "Remise en eur sur Produit" },
            ]}
          />{" "}
        </AutoInputsContainerStyle>
        {(addingData.type === "1" ||
          addingData.type == "4" ||
          addingData.type == "6") && (
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <TextInput
              value={addingData.percentage_value}
              label="Pourcentage"
              type="number"
              onChange={onChange("percentage_value")}
              error={t(addingError.percentage_value)}
            />{" "}
          </AutoInputsContainerStyle>
        )}

        {(addingData.type == "3" ||
          addingData.type == "7" ||
          addingData.type == "5") && (
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
            <TextInput
              value={addingData.eur_value}
              label="Euro"
              type="number"
              onChange={onChangeEurValue}
              error={t(addingError.eur_value)}
            />{" "}
          </AutoInputsContainerStyle>
        )}

        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue="unlimited"
          onChange={(e) => {
            setAddingData({ ...addingData, isLimitedusers: e.target.value });
          }}
          style={{ display: "100%" }}
        >
          <div style={{ display: "inline-flex", width: "100%" }}>
            <FormControlLabel
              value={"limited"}
              control={<Radio />}
              label="limité"
            />
            <FormControlLabel
              value={"unlimited"}
              control={<Radio />}
              label="illimité"
            />
          </div>
        </RadioGroup>
        {addingData.isLimitedusers == "limited" && (
          <TextInput
            value={addingData.nbUser}
            type="number"
            label={"Nombre d'utilisations"}
            onChange={onChange("nbUser")}
            error={t(addingError.nbUser)}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              value={addingData.isLinkedUsers}
              onChange={(e) => {
                setAddingData({
                  ...addingData,
                  isLinkedUsers: e.target.checked,
                });
              }}
            />
          }
          label="lier à des utilisateurs spécifiques"
        />
        <br />
        {addingData.isLinkedUsers && (
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={addingData.isUserExist}
            onChange={(e) => {
              setAddingData({ ...addingData, isUserExist: e.target.value });
            }}
            style={{ display: "100%" }}
          >
            <div style={{ display: "inline-flex", width: "100%" }}>
              <FormControlLabel
                value={"abonne"}
                control={<Radio />}
                label="Utilistateur abonné"
              />
              <FormControlLabel
                value={"non-abonne"}
                control={<Radio />}
                label="Utilistateur non abonné"
              />
              <FormControlLabel
                value={"not exists"}
                control={<Radio />}
                label="Utilisateur non existant"
              />
            </div>
          </RadioGroup>
        )}
        {addingData.isUserExist == "abonne" && (
          <SelectInput
            label={t("promotion_codes.client")}
            error={addingError.client}
            value={addingData.client}
            onChange={onChangeSelectClient("client")}
            data={subscribers?.data?.data?.map((client: any) => ({
              id: client.id,
              name: client.email,
            }))}
          />
        )}
        {addingData.isUserExist == "non-abonne" && (
          <SelectInput
            label={t("promotion_codes.client")}
            error={addingError.client}
            value={addingData.client}
            onChange={onChangeSelectClient("client")}
            data={NonSubscribers?.data?.map((client: any) => ({
              id: client.id,
              name: client.email,
            }))}
          />
        )}
        {addingData.isUserExist == "not exists" && (
          <TextInput
            value={addingData.email}
            label="email"
            onChange={onChange("email")}
            error={t(addingError.email)}
          />
        )}

        {/*  <FormControlLabel control={<Checkbox value={addingData.isLinkedPromoSubscription} onChange={(e) => { setAddingData({ ...addingData, isLinkedPromoSubscription: e.target.checked }) }} />} label="lier code promotion à un abonnement" />
        {addingData.isLinkedPromoSubscription && <SelectInput
          label={t("promotion_codes.subscriptions")}
          error={addingError.subscriptions}
          value={addingData.subscriptions}
          onChange={onChangeSelectSubcscription("subscriptions")}

          data={[{ id: 1, name: "Remise en % sur Produit" }, { id: 2, name: "Livraison gratuite" }, { id: 3, name: "Remise en eur sur Produit" },
          { id: 4, name: "Rabais en % sur livraison" }, { id: 4, name: "Rabais en eur sur livraison" },
          { id: 5, name: "retrait drive en %" }, { id: 6, name: "retrait drive en eur", }

          ]}
        />

        } */}
        <FormControlLabel
          control={
            <Checkbox
              value={addingData.isDiscountMin}
              onChange={(e) => {
                setAddingData({
                  ...addingData,
                  isDiscountMin: e.target.checked,
                });
              }}
            />
          }
          label="Montant minimum du panier"
        />
        {addingData.isDiscountMin && (
          <TextInput
            type="number"
            value={addingData.discount}
            label={t("promotion_codes.discount")}
            onChange={onChange("discount")}
            error={t(addingError.discount)}
          />
        )}
        <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
          <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("promotion_codes.start_date_title")}
                value={dayjs(addingData.start_date)}
                minDateTime={dayjs(
                  new Date(new Date().setDate(new Date().getDate()))
                )}
                defaultValue={dayjs(new Date())}
                onChange={(start: any) => {
                  console.log("start", new Date(start));
                  validate();
                  setAddingData({ ...addingData, start_date: new Date(start) });
                }}
              />
              <p style={{ color: "red" }}>{addingError.start_date}</p>
            </LocalizationProvider>
          </InputStyle>

          <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("promotion_codes.end_date_title")}
                value={dayjs(addingData.end_date)}
                minDateTime={dayjs(addingData.start_date).add(1, "minute")}
                onChange={(end: any) => {
                  console.log("end", end);
                  validate();
                  setAddingData({ ...addingData, end_date: new Date(end) });
                }}
              />
              <p style={{ color: "red" }}>{addingError.end_date}</p>
            </LocalizationProvider>
          </InputStyle>
        </AutoInputsContainerStyle>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default AddPromotionCode;
