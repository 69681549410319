import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/layouts/DashboardLayout/DashboardLayout";
import StockManagementTable from "./StockManagementTable";


const StockManagement = () => {
  return (
    <DashboardLayout
      title={"Arrivage"}
      selectedPath={RouteIdEnum.Stock}
    >
      <StockManagementTable />
    </DashboardLayout>
  );
};

export default StockManagement;
