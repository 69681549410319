import { useState, useEffect } from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { FaArrowRight } from 'react-icons/fa';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import AddClientModal from "./AddClientModal";
import Table from 'components/common/Table/Table';
import SearchInput from 'components/common/SearchInput/SearchInput';
import StatusBadge from 'components/common/StatusBadge/StatusBadge';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import { RouteIdEnum } from 'config/enums';
import useClientList from './useClientList';
import { generateLoginToken } from 'UpdatedFeatures/api';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { ActionContainerStyle } from './Client.style';
import { IClientData } from './Client.types';
import { useTranslation } from 'react-i18next';

const Client = () => {
  const [userId, setUserId] = useState<number | null>(null);
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    clientData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    updateClientStatus,
  } = useClientList();

  const { data: userData } = useQuery(
    {
      queryKey: ['getUserToken', userId],
      queryFn: () => generateLoginToken(userId),
      enabled: !!userId
    }
  );

  useEffect(() => {
    if (userData?.data.token && process.env.REACT_APP_REDIRECT_URL) {
      const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}?${userData.data.token}`;
      window.open(redirectUrl, '_blank');
      setUserId(null)
    }
  }, [userData]);

  return (
    <DashboardLayout
      title={t("client.page_title")}
      selectedPath={RouteIdEnum.Clients}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1} alignItems="center">
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <button
          onClick={handleOpenModal}
          className="flex items-center justify-center px-3 py-1 h-10 rounded-full bg-[#b269ae] text-white font-semibold transition-all duration-300 hover:bg-yellow-400"
        >
          <span className="text-sm">+ Ajouter</span>
        </button>

        {isModalOpen && (
          <AddClientModal onClose={handleCloseModal} />
        )}
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "first_name",
            title: t("client.first_name"),
            onClick: onOrderChange("first_name"),
            asc: orderBy === "first_name" ? orderDir === "asc" : undefined,
          },
          {
            key: "last_name",
            title: t("client.last_name"),
            onClick: onOrderChange("last_name"),
            asc: orderBy === "last_name" ? orderDir === "asc" : undefined,
          },
          {
            key: "email",
            title: t("client.email"),
            onClick: onOrderChange("email"),
            asc: orderBy === "email" ? orderDir === "asc" : undefined,
          },
          {
            key: "phone",
            title: t("client.phone"),
          },
          {
            key: "delivery_address",
            title: t("client.delivery_address"),
          },
          {
            key: "payment_expiration",
            title: t("client.payment_expiration"),
          },
          {
            key: "status",
            title: t("client.status"),
          },
          {
            key: "created_at",
            title: t("common.created_at"),
            onClick: onOrderChange("created_at"),
            asc: orderBy === "created_at" ? orderDir === "asc" : undefined,
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={clientData?.data.map((e: IClientData) => {
          return {
            first_name: <p>{e.first_name}</p>,
            last_name: <p>{e.last_name}</p>,
            email: <p>{e.email}</p>,
            phone: <p>{e.phone}</p>,
            delivery_address: <p>{e.delivery_address}</p>,
            payment_expiration: (
              <div>
                {e.payment_expiration === null
                  ? "-"
                  : moment.unix(e.payment_expiration).isBefore(moment())
                    ? "Expiré"
                    : moment.unix(e.payment_expiration).format("DD/MM/YYYY")}
              </div>
            ),
            status: <StatusBadge isEnabled={e.status ? true : false} />,
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            action: (
              <ActionContainerStyle>
                {
                  <>
                    <Tooltip
                      title={
                        e.is_enable ? t("client.disable") : t("client.enable")
                      }
                    >
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={updateClientStatus(e.id)}
                      >
                        {e.is_enable ? <LockOpenIcon /> : <LockIcon />}
                      </IconButton>
                    </Tooltip>
                    <button
                      type="button"
                      className="text-[#b269ae] hover:text-[#b269ae]"
                      onClick={() => setUserId(e.id)}
                    >
                      <FaArrowRight size={16} />
                    </button>
                  </>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: clientData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
    </DashboardLayout>
  );
}
export default Client;