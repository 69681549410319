import logo from "../../assets/img/logo.svg";
import React, { useRef, useEffect } from "react";

const Reciept = ({ open, close, order }) => {
  const componentRef = useRef();
  const productsArray = Object.values(order?.order.products || {});
  const isSubscribed = order?.order.includes_subscription === 1;
  const total_ttc = order?.order.total_ttc || 0;
  const subTotal = order?.order.products_ht - order?.order.products_tax || 0;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        close();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [open, close]);

  const handlePrint = () => {
    const printContent = componentRef.current;
    if (!printContent) return;

    const originalClass = printContent.className;
    printContent.className += " print-only"; // Add a print-specific class

    window.print();

    printContent.className = originalClass; // Revert to original class
  };

  if (!open) return null;

  return (
    <div
      className="fixed inset-0 z-[9999] overflow-y-auto "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
        onClick={close}
      />

      <div className="flex items-center justify-center min-h-screen p-4 text-center">
        <div
          ref={componentRef}
          className="receipt-content bg-white pb-4 rounded-lg shadow-lg px-8 max-w-xl mx-auto relative"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center justify-between mb-8">
            <img className="h-40 w-40" src={logo} alt="Logo" />
            <div className="text-sm text-gray-700 ml-20 font-bold">
              <div>Email: kd@gmail.com</div>
              <div>Site web: www.kdmarche.com</div>
            </div>
          </div>

          <div className="text-gray-700">
            <div className="font-bold text-lg mb-4 text-[--ExtraColor]">
              FACTURE LOLO DRIVE
            </div>
            <div className="font-semibold text-lg">
              Numéro de Facture: {order.order.id}
            </div>
          </div>

          <div className="border-b-2 border-gray-300 pb-4 mb-8" />
          <div className="border-b-2 border-gray-300 pb-8 mb-8">
            <h2 className="text-2xl font-bold mb-4">Facturé à :</h2>
            <div className="text-gray-700 mb-2">
              {order?.order.user.first_name} {order?.order.user.last_name} -{" "}
              {isSubscribed ? "Abonné" : "Non Abonné"}
            </div>
            <div className="text-gray-700 mb-2">
              {order?.order.user.address}
            </div>
            <div className="text-gray-700 mb-2">
              {order?.order.user.city}, {order?.order.user.country}{" "}
              {order?.order.user.zip}
            </div>
            <div className="text-gray-700">{order?.order.user.email}</div>
          </div>

          <table className="w-full text-left mb-8">
            <thead>
              <tr>
                <th className="text-gray-700 font-bold uppercase py-2 px-4">
                  Nom
                </th>
                <th className="text-gray-700 font-bold uppercase py-2 px-4">
                  Quantité
                </th>
                <th className="text-gray-700 font-bold uppercase py-2 px-4">
                  Prix
                </th>
                <th className="text-gray-700 font-bold uppercase py-2 px-4">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {productsArray.map((item) => (
                <tr key={item.id}>
                  <td className="py-4 px-4 text-gray-700">
                    {item.product_title}
                  </td>
                  <td className="py-4 px-8 text-gray-700">{item.quantity}</td>
                  <td className="py-4 px-4 text-gray-700">
                    €{parseFloat(item.price_ht).toFixed(2)}
                  </td>
                  <td className="py-4 px-4 text-gray-700">
                    €{(item.price_ht * item.quantity).toFixed(2)}
                  </td>
                </tr>
              ))}
              {isSubscribed && (
                <tr>
                  <td className="py-4 px-4 text-gray-700">Abonnement:</td>
                  <td></td>
                  <td></td>
                  <td className="py-4 px-4 text-gray-700">€90.33</td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="flex justify-center mb-8">
            <div className="text-gray-700 mr-2">Sous-total:</div>
            <div className="text-gray-700">€{subTotal}</div>
          </div>
          <div className="flex justify-center mb-8">
            <div className="text-gray-700 mr-2">Taxe TTC:</div>
            <div className="text-gray-700">
              €{(total_ttc - subTotal - (isSubscribed ? 90.33 : 0)).toFixed(2)}
            </div>
          </div>
          <div className="flex justify-center items-center mb-8">
            <div className="text-gray-700 mr-2">Total:</div>
            <div className="text-gray-700 font-bold text-xl">€{total_ttc}</div>
          </div>
          <div className="text-center text-gray-700 mb-4 font-semibold">
            Merci pour votre achat. À bientôt !
          </div>
          <div className="flex justify-between gap-x-3">
            <button
              type="button"
              onClick={handlePrint}
              className="mt-3 inline-flex justify-center rounded-md bg-[#b269ae] px-4 py-2 text-sm font-semibold text-white shadow-sm  hover:opacity-50 duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[--secondaryColor] print-hide"
            >
              Imprimer
            </button>
            <button
              type="button"
              className="print-hide mt-3 inline-flex justify-center rounded-md bg-gray-100 px-4 py-2 text-sm font-semibold text-black shadow-sm hover:opacity-75"
              onClick={close}
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reciept;
