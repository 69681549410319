import React from 'react'
import { IoMdDownload } from "react-icons/io";
import { HiDocumentText } from "react-icons/hi2";
import { toast } from 'react-toastify';
const ImportExport = () => {
    const handleClick = () => {
        toast.info("Cette fonctionnalité est en train de Developpement.")
    }
    return (
        <div className='flex justify-center items-center gap-2'>
            <button
                type="button"
                onClick={handleClick}
                className="rounded-full flex justify-center items-center gap-1  bg-[#b269ae] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            >
                <IoMdDownload size={20} />
                Export
            </button>
            <button
                type="button"
                onClick={handleClick}
                className="rounded-full flex justify-center items-center gap-1  bg-[#b269ae] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            >
                <HiDocumentText size={20} />
                Import
            </button>
        </div>
    )
}

export default ImportExport